import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {useSelector} from 'react-redux';
import {BrowserRouter as Router, Switch, Route, Link, BrowserRouter, Redirect} from "react-router-dom";
import Login from './mobile/account/Login';
import Auth from './mobile/account/Auth';
import Dashboard from './mobile/dashboard/Main'
import Onboard from './mobile/onboard/Main';
import Requests from './mobile/requests/Main';
import Listing from './mobile/listings/Main';
import Profile from './mobile/profile/Main';





const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })
    return isMobile ? children : null
  }

export default function Main(){
    const user = useSelector(state => state.user);

    return(
        <Mobile>
        <Switch>
        <Route exact path="/" component={Login} />
             <Route exact path="/login" component={Login} />
             <Route exact path="/onboard" component={Onboard} />
             <PrivateRoute loggedIn={user.loggedIn} exact path="/profile" component={Profile} />
             <PrivateRoute loggedIn={user.loggedIn} exact path="/auth" component={Auth} />
             <PrivateRoute loggedIn={user.loggedIn} exact path="/dashboard/:page" component={Dashboard} />
             <PrivateRoute loggedIn={user.loggedIn} exact path="/dashboard" component={Dashboard} />
             <PrivateRoute loggedIn={user.loggedIn} exact path="/requests/:page" component={Requests} />
             <PrivateRoute loggedIn={user.loggedIn} exact path="/requests" component={Requests} />
             <PrivateRoute loggedIn={user.loggedIn} exact path="/listings/:page" component={Listing} />
             <PrivateRoute loggedIn={user.loggedIn} exact path="/listings" component={Listing} />
        </Switch>
        </Mobile>
    )
}



const PrivateRoute = ({component: Component, loggedIn,  ...rest}) => (
    <Route
      {...rest}
      render={props => loggedIn
      ? (<Component {...props} />) : (<Redirect to={{pathname: "/"}} />)}
/>)