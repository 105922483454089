export function create(form) {
  var status = "error";
  var message = "";
  if (form.title == "" || form.title == null) {
    message = "Please provide the title of the listing";
  } else if (form.description == "" || form.description == null) {
    message = "Please provide a description of the listing";
  } else if (form.url == "" || form.url == null) {
    message = "Please provide a location of the listing";
  } else if (form.type == "" || form.type == null) {
    message = "Please provide a location of the listing";
  } else {
    status = "success";
  }

  return [status, message];
}
