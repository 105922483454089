import {axiosHandler, defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_PORTFOLIO, FETCH_PORTFOLIOS} from '../constants/portfolio';

import * as validator from '../validators/portfolio';

export function getPortfolio(body){
  const url = '/realtor/portfolio/all';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, body);
      if(status == "ok"){
        if(res.response != null){
          dispatch(fetchPortfolios(res.response.portfolios));
          console.log(res)
        }else{
        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}

export function createPortfolio(body){
    const url = '/realtor/portfolio/create';
    return async dispatch => {
      dispatch(wait(true));
      
      var [status, message] = validator.create(body);
      if(status == "success"){
        var [res, status] = await defaultHandler(url, body);
        console.log(res)
        if(status == "ok"){
          if(res.response != null){
            dispatch(fetchPortfolios(res.response.portfolios));
          }else{

          }
        }else{
          dispatch(unexpectedError())
        }
      }else{
        dispatch(notify({message:message, status:"error", show: true}));  
      }
      dispatch(wait(false))
    }
        
}

export function updatePortfolio(body, history){
  const url = '/realtor/portfolio/update';
  return async dispatch => {
    dispatch(wait(true));
    var [status, message] = validator.create(body);
    if(status == "success"){
      var [res, status] = await defaultHandler(url, body);
      console.log(res);
      if(status == "ok"){
        if(res.response != null){
          dispatch(fetchPortfolios(res.response.portfolios));
          dispatch(notify({message:"Portfolio updated successfully", status:"success"}))
        }else{

        }
      }else{
        dispatch(unexpectedError())
      }
    }else{
      dispatch(notify({message:message, status:"error", show: true}));  
    }
    dispatch(wait(false))
  }     
}


export function deletePortfolio(body, history){
  const url = '/realtor/portfolio/delete';
  return async dispatch => {
    dispatch(wait(true));
    var [status, message] = validator.create(body);
    if(status == "success"){
      var [res, status] = await defaultHandler(url, body);
      console.log(res);
      if(status == "ok"){
        if(res.response != null){
          dispatch(fetchPortfolios(res.response.portfolios));
          dispatch(notify({message:"Portfolio removed successfully", status:"success"}))
          history.push("/portfolios")
        }else{

        }
      }else{
        dispatch(unexpectedError())
      }
    }else{
      dispatch(notify({message:message, status:"error", show: true}));  
    }
    dispatch(wait(false))
  }     
}

export function fetchPortfolio(payload){
  return { type: FETCH_PORTFOLIO, payload }
}

export function fetchPortfolios(payload){
  return { type: FETCH_PORTFOLIOS, payload }
}