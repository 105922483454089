import { axiosHandler, defaultHandler } from "../requests/global.js";
import { unexpectedError, wait, notify } from "./index";
import { FETCH_REVIEW, FETCH_REVIEWS } from "../constants/reviews";

import * as validator from "../validators/listing";

export function getReviews(body) {
  const url = "/app/review/entity";
  return async (dispatch) => {
    dispatch(wait(true));
    try {
      const [res, status] = await defaultHandler(url, body);
      dispatch(fetchReviews(res ? res.response.reviews : []));
    } catch (ex) {
      console.log(ex);
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function fetchReview(payload) {
  return { type: FETCH_REVIEW, payload };
}

export function fetchReviews(payload) {
  return { type: FETCH_REVIEWS, payload };
}
