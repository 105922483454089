import * as React from 'react';
import {useState} from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';


import Default from './Default';
import FooterMenu from '../../components/mobile/page/FooterMenu';


export default function BottomAppBar() {
    
  return (
    <div>
      

      <div style={{padding:5}}>
      <Default />
      </div>

      <div style={{marginTop:150}}/>
     
      <FooterMenu/>
      
    </div>
  );
}