import {FETCH_PORTFOLIO, FETCH_PORTFOLIOS} from  "../constants/portfolio";


const initialState = {
  portfolio:{
  },
  portfolios:[]
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_PORTFOLIO:
      return Object.assign({}, state, {
         portfolio: action.payload
       });
    case FETCH_PORTFOLIOS:
      return Object.assign({}, state, {
          portfolios: action.payload
      });
  }
  return state;
}

export default reducer;
