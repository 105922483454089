import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography  from '@mui/material/Typography';
import Grid  from '@mui/material/Grid';
import IconButton  from '@mui/material/IconButton';
import Paper  from '@mui/material/Paper';



import Avatar from '@mui/material/Avatar';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {notify} from '../../actions/index';

export default function Main(props){
    const dispatch = useDispatch();
    const history = useHistory();
    const listing = useSelector(state => state.onboard.listing);

    function handleClick(){
        props.complete()
    }

    return(
        <div style={{padding:20, height:"100%"}}>
           
                <Typography variant="h4" fontFamily={"InterBold"}>Share</Typography>
               

                    <div style={{display:"flex", justifyContent:"center"}}>
                    <img style={{width:"90%"}} src={require("../../illustrations/complete.svg")}/>
                    </div>
                    

                    <div style={{marginTop:10}}>
                    <Paper elevation={0} style={{padding:10, borderWidth:1, borderStyle:"solid", borderColor:"#E5E4E2", elevation:0}}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item md={10}>
                            <Typography variant="body1" color="textSecondary">Shareable Link</Typography>
                            <Typography variant="body1">https://listings.rentage.app/{listing.url}</Typography>
                        </Grid>
                        <Grid item>
                        <CopyToClipboard text={"https://listings.rentage.app/"+ listing.url}
                        onCopy={() => dispatch(notify({message:"Link Copied", status:"success"}))}>
                         <IconButton><ContentCopyIcon /></IconButton>
                        </CopyToClipboard>
                           
                        </Grid>
                    </Grid>
                    </Paper>
                    
                    </div>
                   
            <div style={{marginTop:10}} />
            <div style={{display:"flex", justifyContent:"flex-end"}}>
                <Button onClick={() => history.push("/dashboard/summary")} 
                variant="contained" fullWidth><Typography variant="body1" fontFamily={"UberBold"}>Go to dashboard</Typography></Button>
            </div>
          

        </div>
    )
}