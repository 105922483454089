import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import ButtonBase from '@mui/material/ButtonBase';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import AddIcon from '@mui/icons-material/Add';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import {wait, notify, unexpectedError} from '../../../../actions/index';

import {uri} from '../../../../constants/settings';
import { CardActionArea } from '@mui/material';

const base64 = require('base-64');

const styles = {
    input:{
        display: 'none',
    }
}

export default function Main(props){
    const dispatch = useDispatch();
    const [form, setForm] = useState({preview:""});
    const user = "";
    const pass = "";
    const credentials = user + ":" + pass;


    useEffect( () =>  {
      fetchPhoto();
    }, []);

    async function fetchPhoto(){
      const user = "mojeed.oyedeji@gmail.com";
      const pass = "omopeyemi";
      const credentials = user + ":" + pass;
      var url = "";
        if(uri.mode == "test"){
          url = uri.test + '/realtor/images/fetchImage/';
        }else if(uri.mode == "live"){
          url = uri.live  + '/realtor/images/fetchImage/';
        }else{
          url = uri.dev  + '/realtor/images/fetchImage/';
        }

      await fetch(url, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': "Basic " + base64.encode(credentials)
        },
        body: JSON.stringify({
          uri: props.uri,
          type: "asset"
        })
      })
      .then((response) => response.blob())
      .then((res) => {
        if (res.size > 100){
          setForm({
            photo: res,
            preview: URL.createObjectURL(res),
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
    }



    async function onChange(e){
        e.preventDefault()
        if (e.target.files.length) {
        const formData = new FormData();
        formData.append('photo', e.target.files[0]);
        formData.append('type', 'asset');
        
        var url = "";

        if(uri.mode == "test"){
          url = uri.test + '/realtor/images/upload/';
        }else if(uri.mode == "live"){
          url = uri.live  + '/realtor/images/upload/';
        }else{
          url = uri.dev  + '/realtor/images/upload/';
        }
       
       
        
        setForm({
              photo:e.target.files[0],
              preview: URL.createObjectURL(e.target.files[0]),
              upload: 1,
            });
        await fetch(url, {
            method: 'POST',
            headers: {
              'Authorization': "Basic " + base64.encode(credentials)
            },
            body:formData,
          })
          .then((response) => response.json())
          .then((res) => {
            dispatch(notify({message: "Image uploaded", status: "success"}))
            props.onComplete(res.response.image);
          })
          .catch((error) => {
            console.log(error)
            dispatch(notify({message: "Unexpected error occured", status: "error"}))
          });
        }
      }

    return(
        <Card elevation={0}>
        
        <CardActionArea component="label" disabled={false} style={{width:"100%"}}>
        <input
        onChange={event => onChange(event)}
        accept="image/*"
        id={props.name}
        style={styles.input}
        multiple
        type="file"
        />
        {form.preview == "" ? 
            <Avatar variant="square" style={{width:"100%", height:100}} >
              <AddIcon fontSize="large" /> </Avatar>:
            <Avatar variant="square" style={{width:"100%", height:100}} 
             src={form.preview} > <AddPhotoAlternateIcon fontSize="large" /> </Avatar>
        }
        </CardActionArea>
        </Card>
    )
}