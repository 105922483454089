import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography  from '@mui/material/Typography';
import TextField  from '@mui/material/TextField';
import MenuItem  from '@mui/material/MenuItem';
import IconButton  from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import GridItem from '../../components/mobile/listing/GridItem';
import AddListing from '../../components/mobile/listing/AddListing';
import { blue, red } from '@mui/material/colors';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Main(){
    const history = useHistory();
    const portfolio = useSelector(state => state.portfolio.portfolio); 
    const [modal, setModal] = useState();
    const [display, setDisplay] = useState(0);
    const [tab, setTab] = useState(0);

    
    function handleClick(){
      setModal(true)
    }

  

    
    

   

    return(
        <div>

         <AddListing open={modal} close={() => setModal(false)} />

     
        <Grid container direction="row" justifyContent="center">
        
        <Grid item xs={10}>

        <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                <Typography variant="h4" fontFamily={"InterBold"}>Listings</Typography>
                <Typography variant="body1" color="textSecondary">Create and manage your listings</Typography>
                </Grid>

                <Grid item>
                <IconButton onClick={handleClick} variant="contained">
                <AddIcon fontSize='large' color="primary" />
                </IconButton>
                </Grid>
            </Grid>

            <div style={{marginTop:30}}/>
        
        <DataDisplay />
          
        </Grid>
      </Grid> 
       
               

            
            
                 
        </div>
    )
}


function DataDisplay(){
  const listings = useSelector(state => state.listing.listings);
  const [data, setData] = useState(listings ? listings : []);

  useEffect(() => {
    setData(listings ? listings : [])
  }, [listings])


  function handleFilter(searchText, location){
     
    if(location == "all"){
      setData(listings.filter((item) => item.state != location).filter(item => item.title.includes(searchText))) 
    }else{
      setData(listings.filter((item) => item.state == location).filter(item => item.title.includes(searchText)))
    }  
 }

  return(

    <>
      <Toolbar update={(search, location) => handleFilter(search, location)} />
        <div style={{marginTop:10}}/>
      <Grid container direction="row" spacing={1}>
       {data.map((item) => (
           <Grid item xs={12}>
           <GridItem data={item} />
           </Grid>
       ))}
      </Grid>
      </>
  )
}


function Toolbar(props){
  const [form, setForm] = useState({search: "", location:"all"});


  function handleChange(event){
    const {name, value} = event.target;
    if(name == "search"){
      props.update(value, form.location)
    }else{
      props.update(form.search, value)
    }
    setForm(form => ({...form, [name]: value}))
    
  }

  return(
    <Grid container direction="row" spacing={1}>
        <Grid item xs={8}>
        <TextField
          required
          fullWidth
          id="search"
          name="search"
          label="Search"
          defaultValue=""
          onChange={handleChange}
          variant="filled"
        />
        </Grid>
        <Grid item xs={4}>
        <TextField
          required
          fullWidth
          id="location"
          name="location"
          label="Location"
          select
          onChange={handleChange}
          defaultValue="all"
          variant="filled"
        >
          <MenuItem value={"all"}> All </MenuItem>
          <MenuItem value={"lagos"}> Lagos </MenuItem>
          <MenuItem value={"abuja"}> Abuja </MenuItem>
        </TextField>
        </Grid>
        <Grid item md={2}>
         
        </Grid>
      </Grid>
  )
}