export function register(form){
  let status = "error", message = "";
  if (form.name === null){
    message = "provide a business name";
  }else if(form.name.length < 6){
    message = "provide a valid business name";
  }else if(form.username.length < 6){
    message = "provide a valid username";
  }else if(form.phone.length < 11 ){
    message = "provide a valid phone number";
  }else if(form.email === ""){
    message = "please provide a valid email address";
  }else{
    message = "please wait..."
    status = "success";
  }
  return [status, message]
}


export function profile(form){
  var status = "error"; var message = ""
  if(form.name == "" || form.name == null){
      message = "Please provide your name"
  }else if(form.phone == "" || form.phone == null){
      message = "Please provide your phone number"
  }else if(form.email == "" || form.email == null){
      message = "Please provide your email address"
  }else if(form.company_name == "" || form.company_name == null){
      message = "Please provide your company name"
  }else if(form.company_reg == "" || form.company_reg == null){
      message = "Please provide your company registration number"
  }else if(form.company_address == "" || form.company_address == null){
      message = "Please provide your company address"
  }else if(form.about == "" || form.about == null){
      message = "Please describe your business"
  }else{
      status = "success"
  }

  return [status, message]
}


export function survey(form){
  let status = "error", message = "";
  if(form.dishes == "" || form.dishes == null){
    message = "provide select a dish category";
  }else if(form.daily_order == "" || form.daily_order == null){
    message = "please select your estimated daily order"
  }else if(form.processing_times == "" || form.processing_times == null){
    message = "please select your order processing times";
  }else if(form.daily_revenue_projection == "" || form.daily_revenue_projection == null){
    message = "please select your daily revenue projection";
  }else if(form.average_delivery_fee == "" || form.average_delivery_fee == null){
    message = "please select your average delivery fee";
  }else{
    message = "please wait..."
    status = "success";
  }
  return [status, message]
}
