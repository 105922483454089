import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MouseIcon from "@mui/icons-material/Mouse";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Rating from "@mui/material/Rating";

import AddLink from "../../components/web/linkings/AddLink";
import { getLinks } from "../../actions/artifacts";
export default function Main() {
  const dispatch = useDispatch();
  const [create, setCreate] = useState(false);
  const user = useSelector((state) => state.user.user);
  const linkings = useSelector((state) => state.artifacts.linkings);
  const [data, setData] = useState(linkings ? linkings : []);
  const [modal, setModal] = useState({ add: false, edit: false });

  useEffect(() => {
    const body = { id: user.id };
    dispatch(getLinks(body));
  }, []);

  useEffect(() => {
    setData(linkings ? linkings : []);
  }, [linkings]);

  return (
    <div>
      <AddLink
        open={modal.add}
        close={() => setModal((modal) => ({ ...modal, add: false }))}
      />
      <Grid container direction="row">
        <Grid item md={6}>
          <Typography variant="h4" style={{ fontFamily: "InterBold" }}>
            Links
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Manage your links
          </Typography>
        </Grid>
      </Grid>

      <div style={{ marginTop: 20 }} />
      <Grid container direction="row">
        <Grid item md={2}>
          <Button
            onClick={() => setModal((modal) => ({ ...modal, add: true }))}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            <Typography variant="body1" style={{ fontFamily: "InterBold" }}>
              Add Link
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={2}>
        <Grid item md={7}>
          <div style={{ marginTop: 20 }} />
          {data.length > 0 ? (
            data.map((item) => <LinkItem data={item} />)
          ) : (
            <Typography color="textSecondary" align="center" variant="body1">
              {"  No links yet "}
            </Typography>
          )}
        </Grid>
        <Grid item md={5}></Grid>
      </Grid>
    </div>
  );
}

function LinkItem(props) {
  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <Grid container direction="row" spacing={1} alignItems="flex-start">
        <Grid item md={1}>
          <Typography variant="body1" fontFamily={"InterBold"}>
            MAR
          </Typography>
          <Typography variant="body1" fontFamily="SpaceBold">
            11
          </Typography>
        </Grid>
        <Grid item md={11}>
          <Grid
            container
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Grid item md={8}>
              <Typography variant="body1" align="justify">
                {props.data.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" align="justify">
                {props.data.description}
              </Typography>
            </Grid>

            <Grid item md={1}>
              <Chip
                label={props.data.clicks || 0}
                icon={<MouseIcon />}
                variant="outlined"
              />
            </Grid>

            <Grid item md={2}>
              <IconButton>
                <OpenInNewIcon color="success" />
              </IconButton>
              <IconButton>
                <EditIcon color="warning" />
              </IconButton>
              <IconButton>
                <DeleteIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ marginTop: 5 }} />
      <Divider />
    </div>
  );
}
