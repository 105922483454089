import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography  from '@mui/material/Typography';
import Grid  from '@mui/material/Grid';
import FormControl  from '@mui/material/FormControl';
import FormLabel  from '@mui/material/FormLabel';
import FormControlLabel  from '@mui/material/FormControlLabel';
import Radio  from '@mui/material/Radio';
import RadioGroup  from '@mui/material/RadioGroup';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Notify from '../../components/alerts/Main';
import {notify} from '../../actions/index';
import {fetchListing} from '../../actions/onboard';

import * as validator from '../../validators/onboard';

import AssetImage from '../../components/image/onboarding/AssetImage';


const initialState = {
    title: "",
    location: "",
    description: "",
    bedrooms: "",
    bathrooms: "",
    kitchens: '',
    total_area: "",
    property_type: ""
}


export default function Main(props){
    const dispatch = useDispatch();
    
    const listing = useSelector(state => state.onboard.listing)
    const realtor = useSelector(state => state.onboard.realtor);

    const [form, setForm] = useState(listing || {});

    function handleClick(){
        var [status, message] = validator.listing(form);
        if(status == "success"){
            const body = {realtor: realtor, listing: form};
            dispatch(fetchListing(form))
            props.complete()
        }else{
            dispatch(notify({show:true, message: message, status: status}));
        }
    }

    function handleChange(event){
        const {name, value} = event.target;
        setForm(form => ({...form, [name]:value}));
    }

    return(
        <div style={{padding:20, height:"100%"}}>
            
                <Typography variant="h6" style={{fontFamily:"InterBold"}}>Add Listing</Typography>
                <Typography variant="body1" color="textSecondary">Please add a listing</Typography>
                

                <Notify />

               

                <div style={{marginTop:20}}/>
              

                <Grid item xs={12}>
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                    <Typography variant="body2" style={{fontSize:10}}>Property Type</Typography></FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="type"
                        defaultValue={"sale"}
                        onChange={handleChange}
                    >
                         <FormControlLabel value="sale" control={<Radio />} label="Sale" />
                        <FormControlLabel value="rental" control={<Radio />} label="Rental" />
                       
                    </RadioGroup>
                    </FormControl>
               
                </Grid>
            
            <div style={{marginTop:10}}>
            <TextField label="Title" 
                    name="title"
                    onChange={handleChange}
                    defaultValue={form.title}
                    placeholder='Full Serviced 4-bedroom Terrace Duplex' 
                    variant="filled" 
                    fullWidth />
            </div>
            
            <div style={{marginTop:10}}>
            <TextField 
             label="Address" 
             name="address"
             onChange={handleChange}
             defaultValue={form.address}
             variant="filled" 
             fullWidth />
            </div>


            <div style={{marginTop:10}}>
            <TextField label="Description" 
            name="description"
            onChange={handleChange}
            defaultValue={form.description}
            multiline="true" rows="4" 
            variant="filled" 
            fullWidth />
            </div>

            <div style={{marginTop:10}}>
            <Grid container direction="row" spacing={1}>
                
                <Grid item xs={12}>
                <TextField label="Price" 
                name="price"
                onChange={handleChange}
                defaultValue={form.price}
                variant="filled" 
                 fullWidth />
                </Grid>
            </Grid>
            </div>

            <div style={{marginTop:10}}>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                <TextField 
                label="Living Rooms" 
                name="livingrooms"
                onChange={handleChange}
                defaultValue={form.livingrooms}
                variant="filled"  fullWidth />
                </Grid>
                <Grid item xs={12}>
                <TextField 
                label="Bedrooms" 
                name="bedrooms"
                onChange={handleChange}
                defaultValue={form.bedrooms}
                variant="filled"  fullWidth /> 
            
                </Grid>
            </Grid>
            
            </div>
            
            <div style={{marginTop:10}}>
            <Grid container direction="row" spacing={1}>
                
                <Grid item xs={12}>
                <TextField 
                label="Bathrooms" 
                name="bathrooms"
                onChange={handleChange}
                defaultValue={form.bathrooms}
                variant="filled"  fullWidth />
                </Grid>
                <Grid item xs={12}>
                <TextField label="Kitchens" 
                name="kitchens"
                onChange={handleChange}
                defaultValue={form.kitchens}
                variant="filled"  fullWidth />
                </Grid>
              
            </Grid>
            
            </div>

          

            <div style={{marginTop:10}}>
            <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={12}>
                <TextField 
                label="Garages" 
                name="garages"
                onChange={handleChange}
                defaultValue={form.garages}
                variant="filled" fullWidth />
                </Grid>
                <Grid item md={6} xs={12}>
                <TextField label="Total Area (sqft)" 
                name="total_area"
                onChange={handleChange}
                defaultValue={form.total_area}
                variant="filled" fullWidth />
                </Grid>
            </Grid>
           
            </div>
           
            

           
            
           
           
         
            <div style={{marginTop:10}}/>
            <Typography variant="body2" color="textSecondary">Please upload at least two images</Typography>
            <div style={{marginTop:10}}/>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={6}>
                        <AssetImage uri={form.image1} onComplete={(value) => setForm((form) => ({...form, image1: value}))} />
                    </Grid>
                    <Grid item xs={6}>
                        <AssetImage uri={form.image2} onComplete={(value) => setForm((form) => ({...form, image2: value}))} />
                    </Grid>
                    <Grid item xs={6}>
                        <AssetImage uri={form.image3} onComplete={(value) => setForm((form) => ({...form, image3: value}))} />
                    </Grid>
                    <Grid item xs={6}>
                        <AssetImage uri={form.image4} onComplete={(value) => setForm((form) => ({...form, image4: value}))} />
                    </Grid>
                    <Grid item>
                    <IconButton>
                        
                    </IconButton>
                    </Grid>
            </Grid>


            <div style={{marginTop:10}}/>
            <div style={{display:"flex", justifyContent:"flex-end"}}>
                <Button fullWidth onClick={handleClick} variant="contained">
                    <Typography variant="body1" fontFamily={"UberBold"}>Save and Continue</Typography>
                </Button>
            </div>
            

        </div>
    )
}