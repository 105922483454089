import { FETCH_LINK, FETCH_LINKS } from "../constants/artifacts";

const initialState = {
  linking: {},
  linkings: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LINK:
      return Object.assign({}, state, {
        linking: action.payload,
      });
    case FETCH_LINKS:
      return Object.assign({}, state, {
        linkings: action.payload,
      });
  }
  return state;
}

export default reducer;
