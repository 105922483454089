import React from 'react';
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';


import {page} from '../../styles/main';

import About from './About';
import Listing from './Listing';
import Preview from './Preview';
import Share from './Share';

const form = {
    realtor:{},
    listing: {},
}

export default function Main(){
    const history = useHistory()
    const [selectedIndex, setSelectedIndex] = React.useState(0);


    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };


    function moveNext(){
        setSelectedIndex(selectedIndex + 1)   
    }

    function handleNavigate(index){
        setSelectedIndex(index);
    }

    

    return(
        <div style={page.root}>
             <Grid direction="row" style={{height:"100vh"}} justifyContent="center" alignItems="center" container >
             <Grid item xs={12} sm={12} style={{height:"80%"}}>
             <Grid container direction="row" style={{height:"100%"}} justifyContent="center">
                
                <Grid item xs={12} sm={8}>
                        <div style={{display:"flex", paddingLeft:20}}>
                        <img style={{width:100, height:25}}  src={require("../../images/logo/1x/Asset 1.png")}/>
                        </div>
                    {screens(selectedIndex, moveNext)}
                </Grid>

             </Grid>
            
                </Grid>
                
             </Grid>
        </div>
    )
}




function screens(index, move){
    switch(index){
        case 0:
            return(<About complete={() => move()} />)
        case 1:
            return(<Listing complete={() => move()} />)
        case 2:
            return(<Preview complete={() => move()} />)
        case 3: 
        return(<Share/>)
    }
}