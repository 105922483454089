import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography  from '@mui/material/Typography';
import ListItem  from '@mui/material/ListItem';
import Chip  from '@mui/material/Chip';
import getSymbolFromCurrency from 'currency-symbol-map';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import Listings from '../../components/mobile/dashboard/Listings';


export default function Main(){
    const user = useSelector(state => state.user.user);

    return(
        <div>
            <div>
            <Typography variant="h6"><b> Hi, {user.name}</b></Typography>
            </div>

            <div style={{marginTop:20}}>
            <Grid container direction="row" spacing={2}>
              <Grid item md={8}>
                <Listings />
               
              </Grid>
              <Grid item md={4}>
            
               
              </Grid>

              
            </Grid>
            <div style={{marginTop:20}}/>
            <Grid container direction="row" spacing={2}>
              <Grid item md={8}>
              </Grid>
              <Grid item md={4}>
              </Grid>
            </Grid>
           
            </div>
            
           
      

      
        </div>
    )
}

