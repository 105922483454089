import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import Typography from "@mui/material/Typography";

import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Rating from "@mui/material/Rating";

import { updateProfile } from "../../actions/user";
import { getReviews } from "../../actions/reviews";
import PieChart from "../../components/web/reviews/Chart";

import { averageRating, ratingDistribution } from "../../functions/chart";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function Main() {
  const dispatch = useDispatch();
  const [display, setDisplay] = useState("grid");
  const [create, setCreate] = useState(false);
  const user = useSelector((state) => state.user.user);
  const reviews = useSelector((state) => state.review.reviews);
  const [data, setData] = useState(reviews ? reviews : []);
  const [form, setForm] = useState(user);
  const pieData = ratingDistribution(reviews);
  const [filter, setFilter] = useState("all");

  function handleChange(event) {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  }

  function handleClick() {
    dispatch(updateProfile(form));
  }

  useEffect(() => {
    const body = { id: user.id };
    dispatch(getReviews(body));
  }, []);

  function handleFilter(value) {
    setFilter(value);
    if (value != "all") {
      const filtered = reviews.filter((item) => item.rating == value);
      setData(filtered ? filtered : []);
    } else {
      setData(reviews ? reviews : []);
    }
  }

  return (
    <div>
      <Grid container direction="row">
        <Grid item md={6}>
          <Typography variant="h4" style={{ fontFamily: "InterBold" }}>
            Reviews
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Manage your reviews
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={2}>
        <Grid item md={7}>
          <Grid container direction="row" justifyContent="flex-end">
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                variant={filter == "all" ? "contained" : "outlined"}
                onClick={() => handleFilter("all")}
              >
                <Typography variant="body1">All</Typography>
              </Button>
              <Button
                variant={filter == "excellent" ? "contained" : "outlined"}
                onClick={() => handleFilter("excellent")}
              >
                <Typography variant="body1">Excellent</Typography>
              </Button>
              <Button
                variant={filter == "good" ? "contained" : "outlined"}
                onClick={() => handleFilter("good")}
              >
                <Typography variant="body1">Good</Typography>
              </Button>
              <Button
                variant={filter == "average" ? "contained" : "outlined"}
                onClick={() => handleFilter("average")}
              >
                <Typography variant="body1">Average</Typography>
              </Button>
              <Button
                variant={filter == "fair" ? "contained" : "outlined"}
                onClick={() => handleFilter("fair")}
              >
                <Typography variant="body1">Fair</Typography>
              </Button>
              <Button
                variant={filter == "poor" ? "contained" : "outlined"}
                onClick={() => handleFilter("poor")}
              >
                <Typography variant="body1">Poor</Typography>
              </Button>
            </ButtonGroup>
          </Grid>

          <div style={{ marginTop: 20 }} />
          {data.length > 0 ? (
            data.map((item) => <ReviewItem data={item} />)
          ) : (
            <Typography color="textSecondary" align="center" variant="body1">
              {"  No reviews yet "}
            </Typography>
          )}
        </Grid>
        <Grid item md={5}>
          <div style={{ justifyContent: "center" }}>
            <Typography
              align="center"
              variant="h6"
              color="textSecondary"
              style={{ fontFamily: "InterBold" }}
            >
              OVERALL RATING
            </Typography>
            <Typography
              align="center"
              variant="h4"
              style={{ fontFamily: "SpaceBold" }}
            >
              {averageRating(pieData)}
            </Typography>
          </div>

          <div style={{ height: 300, width: "100%" }}>
            <PieChart data={pieData} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function ReviewItem(props) {
  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <Grid container direction="row" spacing={1} alignItems="flex-start">
        <Grid item md={1}>
          <Avatar variant="rounded" style={{ width: 50, height: 50 }} />
        </Grid>
        <Grid item md={11}>
          <Grid container direction="row" justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="body1" style={{ fontFamily: "InterBold" }}>
                {" "}
                Jane Doe{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Rating name="read-only" value={5} readOnly />
            </Grid>
          </Grid>

          <Typography variant="body2" align="justify">
            {props.data.review}
          </Typography>

          <div style={{ marginTop: 10 }} />
          <Typography color="textSecondary" variant="body2" align="justify">
            Dec 29, 2023 | 11:03 PM{" "}
          </Typography>
        </Grid>
      </Grid>
      <div style={{ marginTop: 5 }} />
      <Divider />
    </div>
  );
}
