import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';


import Default from './Default';
import Listing from './Listing';
import AddListing from './AddListing';
import EditListing from './EditListing';
import Preview from './Preview';
import FooterMenu from '../../components/mobile/page/FooterMenu';

import {getListings} from '../../actions/listings';

export default function BottomAppBar() {

  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector(state => state.user.user);
  const [open, setOpen] = React.useState(true);
  const [screen, setScreen] = useState("default");

  let { page } = useParams();
    
  useEffect(() => {
    setScreen(page == null ? "default" : page)
  }, [page])

  useEffect(() => {
    const body = {realtor: user.id}
    dispatch(getListings(body))
  }, [])

  return (
    <React.Fragment>
      <CssBaseline />

      <div style={{padding:5, marginBottom:50}}>
      {getScreen(screen)}
     
      </div>
     
      <div style={{marginTop:150}}/>
      <FooterMenu/>
      
    </React.Fragment>
  );
}

function getScreen(screen){
  switch(screen){
    case "default":
      return (<Default  />)
    case "listing":
      return (<Listing  />)
    case "add":
      return (<AddListing  />)
    case "edit":
      return (<EditListing  />)
    case "preview":
      return (<Preview  />)
  }
}