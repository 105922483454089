import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import AssetCardMedia from '../image/listing/AssetCardMedia';


export default function Main(){
    const listing = useSelector(state => state.listing.listing);

    return(
        <div>
            <Grid container direction="row" spacing={1}>
                <Grid item md={8}>

               
                
                <div style={{marginTop:10}}>
                <Typography variant="body2" color="textSecondary"><b>DESCRIPTION</b></Typography>
                <Typography variant="body1">{listing.description}</Typography>
                </div>

                <div style={{marginTop:10}}>
                <Typography variant="body2" color="textSecondary"><b>ADDRESS</b></Typography>
                <Typography variant="body1">{listing.address}</Typography>
                </div>
               
                <div style={{marginTop:10}}>
                <Grid container direction="row">
                    <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary"><b>PRICE</b></Typography>
                    <Typography variant="body1">{listing.price}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary"><b>BEDROOMS</b></Typography>
                    <Typography variant="body1">{listing.bedrooms}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary"><b>LIVING ROOMS</b></Typography>
                    <Typography variant="body1">{listing.livingrooms}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary"><b>GARAGES</b></Typography>
                    <Typography variant="body1">{listing.garages}</Typography>
                    </Grid>
                </Grid>
                
                </div>

                <div style={{marginTop:10}}>
                <Grid container direction="row">
                    <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary"><b>KITCHENS</b></Typography>
                    <Typography variant="body1">{listing.kitchens}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary"><b>TOTAL AREA (SQFT)</b></Typography>
                    <Typography variant="body1">{listing.total_area}</Typography>
                    </Grid>
                </Grid>
                
                </div>
                
               
                <div style={{marginTop:10}}>
                <Typography variant="body1" color="textSecondary"><b>IMAGES</b></Typography>
                <div style={{marginTop:10}}/>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={6}>
                        {listing.image1 != null && 
                             <Card  style={{borderRadius:10}}>
                             <AssetCardMedia uri={listing.image1} />
                             </Card> 
                        }
                       
                    </Grid>
                    <Grid item xs={6}>
                        {listing.image2 !== null &&  
                        <Card  style={{borderRadius:10}}>
                        <AssetCardMedia uri={listing.image2} />
                        </Card> }
                       
                    </Grid>
                    <Grid item xs={6}>
                        {listing.image3 != null && 
                             <Card  style={{borderRadius:10}}>
                             <AssetCardMedia uri={listing.image3} />
                             </Card> 
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {listing.image4 != null && 
                             <Card  style={{borderRadius:10}}>
                             <AssetCardMedia uri={listing.image4} />
                             </Card> 
                        }
                    </Grid>
                </Grid>
                </div>

                

                

                </Grid>
                <Grid item md={4}>
                </Grid>
            </Grid>
        </div>
    )
}