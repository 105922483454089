import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography  from '@mui/material/Typography';
import Grid  from '@mui/material/Grid';

import Avatar from '@mui/material/Avatar';


import * as validator from '../../validators/onboard';
import Notify from '../../components/alerts/Main';
import {notify} from '../../actions/index';
import {fetchRealtor} from '../../actions/onboard';
import UploadAvatar from '../../components/image/onboarding/UploadAvatar';

const initialState = {
    name: "",
    phone: "",
    email: "",
    company_name: "",
    company_reg: "",
    company_address: "",
    about: "",
}


export default function Main(props){
    const dispatch = useDispatch();
    const realtor = useSelector(state => state.onboard.realtor)

    const [form, setForm] = useState(realtor);

    function handleClick(){
        var [status, message] = validator.about(form);
        if(status == "success"){
            props.complete()
            dispatch(notify({show:false, message: message, status: status}));
            dispatch(fetchRealtor(form))
        }else{
            dispatch(notify({show:true, message: message, status: status}));
        }
       
    }

    function handleChange(event){
        const {name, value} = event.target;
        setForm(form => ({...form, [name]:value}));
    }
    return(
        <div style={{padding:20, height:"100%"}}>
            <Grid style={{height:"100%"}} container direction="column" justifyContent="space-between">
                
                <Grid item>
                <Typography variant="h4"><b>About</b></Typography>
                </Grid>

                <Notify />

                <Grid item>
                
            <div style={{}}>
                <UploadAvatar uri={form.avatar} onComplete={(value) => setForm((form) => ({...form, avatar: value }))}/>
            </div>
            <div style={{marginTop:20}}>
            <TextField name="name" 
                       label="Full Name"
                       onChange={handleChange} 
                       defaultValue={form.name}
                       variant="standard" 
                       fullWidth />
            </div>
            
            <div style={{marginTop:10}}>
            <TextField 
            name="phone"
            label="Phone Number" 
            variant="standard" 
            onChange={handleChange}
            defaultValue={form.phone} 
            fullWidth />
            </div>
            
            <div style={{marginTop:10}}>
            <TextField 
            name = "email" 
            onChange={handleChange} 
            defaultValue={form.email}
            label="Email" variant="standard" fullWidth />
            </div>

            <div style={{marginTop:10}}>
            <TextField 
            name = "company_name" 
            onChange={handleChange} 
            defaultValue={form.company_name} 
            label="Company Name" variant="standard" fullWidth />
            </div>

            <div style={{marginTop:10}}>
            <TextField name = "company_reg" 
            onChange={handleChange}
            defaultValue={form.company_reg}  
            label="Company Reg Number" 
            variant="standard" fullWidth />
            </div>

            <div style={{marginTop:10}}>
            <TextField name = "company_address" 
            defaultValue={form.company_address}
            onChange={handleChange}  
            label="Company Address" 
            variant="standard" fullWidth />
            </div>

            <div style={{marginTop:10}}>
            <TextField name = "about" 
            onChange={handleChange}  
            defaultValue={form.about}
            label="About" multiline="true"
             rows="4" variant="standard" fullWidth />
            </div>
           
           
            </Grid>

            <Grid item>
            <div style={{display:"flex", justifyContent:"flex-end"}}>
                <Button name="continue" onClick={handleClick} variant="contained">
                    <Typography variant="body1"><b>Save and Continue</b></Typography>
                </Button>
            </div>
            </Grid>
            </Grid>

          

           

           
           
            
        </div>
    )
}