import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography  from '@mui/material/Typography';
import Stack  from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab';
import ListItem  from '@mui/material/ListItem';
import Avatar  from '@mui/material/Avatar';
import ListItemIcon  from '@mui/material/ListItemIcon';
import Checkbox  from '@mui/material/Checkbox';
import Chip  from '@mui/material/Chip';
import MenuItem  from '@mui/material/MenuItem';
import Select  from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Breadcrumbs  from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import AddIcon from '@mui/icons-material/Add';

import GridItem from '../../components/listing/GridItem';
import { blue, red } from '@mui/material/colors';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Main(){
    const history = useHistory();
    const portfolio = useSelector(state => state.portfolio.portfolio); 
    const [display, setDisplay] = useState(0);
    const [tab, setTab] = useState(0);

    function handleClick(){

    }

    function handleChange(){

    }

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };
    

   

    return(
        <div>

            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                <Typography variant="h4"><b>{portfolio.title}</b></Typography>
                <Typography variant="body1" color="textSecondary">{portfolio.description}</Typography>
                </Grid>

                <Grid item>
                <Button size="large" onClick={() => history.push("/listings/add")} startIcon={<AddIcon />} sx={{elevation:0}} 
                variant="contained"><Typography variant="body1"><b>Add Listing</b></Typography></Button>
                </Grid>
            </Grid>

            <Grid style={{marginTop:20}} container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="/portfolios">
                    Portfolio
                  </Link>
                  <Typography color="text.primary">{portfolio.title}</Typography>
                </Breadcrumbs>
              </div>
              </Grid>
              <Grid item>
               
             
              </Grid>
            </Grid>

        <div style={{marginTop:30}}>
        <Grid container direction="row">
        <Grid item md={2}>
            <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tab}
            sx={{'& .MuiTabs-indicator': { left :0 },
            '& .MuiTab-root': {minHeight:10, padding:1, alignItems:"flex-start", justifyContent:"center"},
            '& .Mui-selected': {  }}} 
            classes={{labelContainer:{padding:0}}}
            onChange={handleTabChange}
            aria-label="vtab">
            <Tab value={0} label={<Typography sx={{textTransform:"none"}}>Properties </Typography>} {...a11yProps(0)} />
            <Tab value={1} label={<Typography sx={{textTransform:"none"}}>Insights </Typography>} {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <Grid item md={10}>
           {tab == 0 && <PropertiesTab/>}
        </Grid>
      </Grid> 
        </div>
               

            
            
                 
        </div>
    )
}


function PropertiesTab(){
  const [display, setDisplay] = useState("grid");
  const [filter, setFilter] = useState(0);

  const handleDisplayChange = (event, newValue) => {
    setDisplay(newValue);
  };

  function handleChange(){

  }


  return(
    <div>
         <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
            <Stack direction={"row"} spacing={1}>
            <Select
                 size="small"
                 value={filter}
                 onChange={handleChange}
               >
                 <MenuItem value={0}>
                   <em>Date Created</em>
                 </MenuItem>
                 <MenuItem value={10}>Views</MenuItem>
               </Select>
            
             <Tabs   sx={{'& .MuiTabs-indicator': {bottom:5 },
                           '& .MuiTab-root': {minHeight:40, minPadding:0, justifyContent:"flex-start"},
                           '& .Mui-selected': {  }}} 
                           size="small"
                           classes={{labelContainer:{padding:0}}}
                           value={display}
                           onChange={handleDisplayChange}>
               <Tab sx={{p:0}} icon={<GridViewIcon />} 
                   iconPosition="start"
                   value="grid" 
                   label={<Typography sx={{fontSize:14}}>Grid</Typography>} {...a11yProps(0)} />
               <Tab sx={{p:0}} 
                   icon={<ViewStreamIcon />}  
                   iconPosition="start"
                   value="list" 
                   label={<Typography sx={{fontSize:14}}>List</Typography>} {...a11yProps(1)} />
             </Tabs>
            </Stack>
             
            </div>
      <div>
            {display == "grid" ? <GridView />  : <ListView />  }
      </div>
    </div>
  )
}

function GridView(props){
    const listings = useSelector(state => state.listing.listings);
    const portfolio = useSelector(state => state.portfolio.portfolio)
    const [data, setData] = useState(listings ? listings.filter(item => item.portfolio == portfolio.id) : []);

    return(
        <Grid container direction="row" spacing={1}>
         {data.map((item) => (
             <Grid item md={3}>
             <GridItem data={item} />
             </Grid>
         ))}
        </Grid>
    )
}



function ListView(){
    return(
      <>
      </>
    )
}

function Item(){
    return(
        <ListItem divider disableGutters>
             <ListItemIcon>
                <Checkbox
                  edge="start"
                  disableRipple
                />
              </ListItemIcon>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                
                <Grid item md={8}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item md={2}>
                          <Avatar style={{borderRadius:5, width:50, height:50}} variant="square">
                          <HomeIcon />
                          </Avatar>
                        </Grid>
                        <Grid item md={8}>
                        <Typography> FULLY SERVICED 4 BEDROOM TERRACE DUPLEX IN IKATE LEKKI</Typography>

                        <Grid container direction="row" spacing={1}>
                          <Grid item>
                            <Chip  style={{padding:0, borderRadius:0}} variant="outlined" color="primary"
                            label={<Typography variant="body2">Active </Typography>} />
                          </Grid>

                          <Grid item>
                            <Chip  style={{padding:0, borderRadius:0}} variant="outlined" color="primary"
                            label={<Typography variant="body2">Land </Typography>} />
                          </Grid>
                        </Grid>
                        
                        
                        </Grid>
                    </Grid>
                    
                </Grid>
               
                <Grid item md={2}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                        <LinkIcon />
                        <Typography> </Typography>
                        </Grid>
                        <Grid item>
                        <VisibilityIcon />
                        <Typography></Typography>
                        </Grid>
                        <Grid item style={{alignItems:"flex-end"}}>
                        <Button variant="outlined">
                            <Typography variant="body2">Publish</Typography>  
                        </Button>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </ListItem>
    )
}