import {SET_USER, LOGGED_IN} from  "../constants/user";


const initialState = {
  user:{
  },
  loggedIn: false
};

function reducer(state = initialState, action) {
  switch (action.type){
    case SET_USER:
      return Object.assign({}, state, {
         user: action.payload
       });
      case LOGGED_IN:
      return Object.assign({}, state, {
         loggedIn: action.payload
      });
  }
  return state;
}

export default reducer;
