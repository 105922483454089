export function about(form){
    var status = "error"; var message = ""
    if(form.name == "" || form.name == null){
        message = "Please provide your name"
    }else if(form.phone == "" || form.phone == null){
        message = "Please provide your phone number"
    }else if(form.email == "" || form.email == null){
        message = "Please provide your email address"
    }else if(form.company_name == "" || form.company_name == null){
        message = "Please provide your company name"
    }else if(form.company_reg == "" || form.company_reg == null){
        message = "Please provide your company registration number"
    }else if(form.company_address == "" || form.company_address == null){
        message = "Please provide your company address"
    }else if(form.about == "" || form.about == null){
        message = "Please describe your business"
    }else{
        status = "success"
    }

    return [status, message]
}

export function listing(form){
    var status = "error"; var message = ""
    if(form.title == "" || form.title == null){
        message = "Please provide the title of the listing"
    }else if(form.description == "" || form.description == null){
        message = "Please provide a description of the listing"
    }else if(form.address == "" || form.address == null){
        message = "Please provide a location of the listing"
    }else if(form.bedrooms == "" || form.bedrooms == null){
        message = "Please specify the number of bedrooms"
    }else if(form.bathrooms == "" || form.bathrooms == null){
        message = "Please specify the number of bathrooms"
    }else if(form.kitchens == "" || form.kitchens == null){
        message = "Please specify the number of kitchens"
    }else if(form.total_area == "" || form.total_area == null){
        message = "Please specify the total area"
    }else if(form.price == "" || form.price == null){
        message = "Please specify the price of the listing"
    }else if(form.image1 == "" || form.image1 == null){
        message = "Please upload at least two images"
    }else if(form.image2 == "" || form.image2 == null){
        message = "Please upload at least two images"
    }else{
        status = "success"
    }

    return [status, message]
}