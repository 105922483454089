import { FETCH_REVIEW, FETCH_REVIEWS } from "../constants/reviews";

const initialState = {
  review: {},
  reviews: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REVIEW:
      return Object.assign({}, state, {
        review: action.payload,
      });
    case FETCH_REVIEWS:
      return Object.assign({}, state, {
        reviews: action.payload,
      });
  }
  return state;
}

export default reducer;
