import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import LinkIcon from "@mui/icons-material/Link";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AutoGraphIcon from "@mui/icons-material/AutoGraphRounded";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenuRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartRounded";
import PersonIcon from "@mui/icons-material/PersonRounded";
import PaymentIcon from "@mui/icons-material/PaymentRounded";
import ReviewsIcon from "@mui/icons-material/ReviewsRounded";
import FolderIcon from "@mui/icons-material/Folder";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/NotificationsActiveRounded";
import EventIcon from "@mui/icons-material/Event";

import UserAvatar from "../../components/profile/UploadAvatar";
import { loggedIn } from "./../../actions/user";
const drawerWidth = 300;

const urls = [
  {
    title: "Summary",
    url: "/dashboard",
    disabled: false,
    visible: true,
    icon: <AutoGraphIcon color="primary" />,
  },

  {
    title: "Listings",
    url: "/listings",
    disabled: false,
    visible: true,
    icon: <HomeIcon color="primary" />,
  },
  {
    title: "Requests",
    url: "/requests",
    disabled: true,
    visible: false,
    icon: <FolderIcon color="primary" />,
  },
  {
    title: "Links",
    url: "/links",
    disabled: false,
    visible: true,
    icon: <LinkIcon color="primary" />,
  },

  {
    title: "Reviews",
    url: "/reviews",
    disabled: false,
    visible: true,
    icon: <ReviewsIcon color="primary" />,
  },
  {
    title: "Billings",
    url: "/billings",
    disabled: false,
    visible: true,
    icon: <PaymentIcon color="primary" />,
  },
  {
    title: "Notifications",
    url: "/notifications",
    disabled: false,
    visible: true,
    icon: <NotificationsIcon color="primary" />,
  },
];
const icons = {
  0: <AutoGraphIcon color="primary" />,
  1: <RestaurantMenuIcon color="primary" />,
  2: <ShoppingCartIcon color="primary" />,
  3: <PersonIcon color="primary" />,
  4: <PaymentIcon color="primary" />,
  5: <ReviewsIcon color="primary" />,
  6: <NotificationsIcon color="primary" />,
};
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Main(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const [open, setOpen] = useState(props.open);
  const menu = Boolean(anchorEl);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleDrawerClose = () => {
    setOpen(false);
    props.handleDrawer(false);
  };

  function handleClick(item) {
    history.push(item.url);
  }

  function handleMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleLogout() {
    dispatch(loggedIn(false));
    localStorage.clear();
    history.push("/");
  }

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          justifyContent: "space-between",
          backgroundColor: "#fff",
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <div>
        <DrawerHeader>
          <div style={{ marginTop: 20 }} />
          <Grid container direction="row" style={{ padding: 10 }}>
            <Grid item>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={{ width: 150, height: 35 }}
                  src={require("../../images/logo/1x/Asset 1.png")}
                />
              </div>
            </Grid>
          </Grid>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {urls.map(
            (item, index) =>
              item.visible && (
                <ListItem
                  disabled={item.disabled}
                  key={item.title}
                  disablePadding
                >
                  <ListItemButton onClick={() => handleClick(item)}>
                    <ListItemIcon color="primary">{item.icon}</ListItemIcon>
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ fontFamily: "InterBold" }}
                    >
                      {item.title}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              )
          )}
        </List>
      </div>

      <ListItem
        style={{
          justifySelf: "flex-end",
          justifyContent: "flex-end",
          justifyItems: "flex-end",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item md={10}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item md={3}>
                <UserAvatar uri={user.avatar} />
              </Grid>
              <Grid item md={9}>
                <Typography variant="body1" style={{ fontFamily: "InterBold" }}>
                  {user.name}
                </Typography>
                <Typography variant="body2" noWrap>
                  {user.email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={2}>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={menu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => history.push("/profile")}>
                <Typography variant="body2">Profile</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography variant="body2">Logout</Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </ListItem>
    </Drawer>
  );
}
