import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Typography  from '@mui/material/Typography';
import Box  from '@mui/material/Box';
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Breadcrumbs  from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import Listing from '../../components/mobile/requests/Listing';

import {getMySuggestion} from '../../actions/suggestion';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Main(){
    const dispatch = useDispatch();
    const [tab, setTab] = useState(0);
    const request = useSelector(state => state.request.request); 
    const suggestions = useSelector(state => state.suggestion.suggestions); 
    const [data, setData] = useState(suggestions ? suggestions.filter((item) => item.request == request.id) : []);
    const realtor = useSelector(state => state.user.user);

    useEffect(() => {
      setData(suggestions ? suggestions.filter((item) => item.request == request.id) : [])
    }, []);

    useEffect(() => {
      const body = {request: request.id, realtor: realtor.id};
      dispatch(getMySuggestion(body));
    }, [])


    function handleClick(){

    }

    const handleChange = (event, newValue) => {
      setTab(newValue);
    };

  

   

    return(
        <div>
           
            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                <Typography variant="h4"><b>Requests</b></Typography>
                <Typography variant="body1" color="textSecondary"></Typography>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>

            <Grid style={{marginTop:20}} container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="/requests">
                    Requests
                  </Link>
                  <Typography color="text.primary">New Request</Typography>
                </Breadcrumbs>
              </div>
              </Grid>
              <Grid item>
               
             
              </Grid>
            </Grid>

            <div style={{marginTop:30}}>
            <Grid container direction="row" spacing={2}>
            
            <Grid item xs={12}>
            <div>
            <Tabs
              value={tab}
              onChange={handleChange}
            >
              <Tab value={0} label="Details" />
              <Tab value={1} label={<Typography>Suggestions 
                <Chip size="small" label={suggestions.filter(item => item.request == request.id).length} /></Typography>} />
            </Tabs>
            </div>
            

            <div style={{marginTop:20}}/>
            {tab == 0 && <Summary data={request} />}
            {tab == 1 && <Listings />}
            </Grid>

           
           
            
            </Grid> 
            </div>     
        </div>
    )
}

function Summary(props){
  return(
    <div>

    <Box sx={{padding:2, borderRadius:5, height:"250px", width: '100%', backgroundColor:"#E5E4E2" }}>
    <div style={{marginTop:20}}/>
    <Grid container direction="row" justifyContent={"space-between"}>
      <Grid item xs={10}>
      <Typography variant="body1" color="textSecondary">POSTED BY</Typography>
      <Typography variant="body1">{props.data.customer}</Typography>
      </Grid>


      <Grid item xs={2}>
      <Chip color="warning" size="small" label={  <Typography variant="body2"> {props.data.status} </Typography>} />
      </Grid>
      </Grid>

      <div style={{marginTop:10}}/>
      <Grid item xs={12}>
      <Typography variant="body1" color="textSecondary">PREFERRED LOCATION</Typography>
      <Typography variant="body1">{props.data.state}, {props.data.location}</Typography>
      </Grid>

   
      

      <div style={{marginTop:10}}/>
      <Grid container direction="row">
      <Grid item xs={12}>
      <Typography variant="body1" color="textSecondary">DESCRIPTION</Typography>
      <Typography variant="body1">{props.data.description || "No Description"}</Typography>
      </Grid>
      </Grid>

      <div style={{marginTop:10}}/>


      <Grid container direction="row">
      
    </Grid>
    </Box>
    </div>
  )
}

function Listings(props){
  const dispatch = useDispatch();
  const [suggest, setSuggest] = useState(false)
  const listings = useSelector(state => state.listing.listings);
  const suggestions = useSelector(state => state.request.suggestions);
  const [data, setData] = useState(listings ? listings : []);

  useEffect(() => {

  }, [])  

  return(
    <div>
      <Typography variant="body1" color="textSecondary"> Suggest some of your listings  </Typography>
    
      <div style={{marginTop:20}}/>
      
      <div style={{marginTop:10}} />
      <Grid container direction="row" spacing={1}>
      {data.map((item) => (
        <Grid item md={4}>
           <Listing data={item}/>
        </Grid>
      ))}
      </Grid>
      
      
    </div>
  )
}



