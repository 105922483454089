import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Typography  from '@mui/material/Typography';
import TextField  from '@mui/material/TextField';
import ListItem  from '@mui/material/ListItem';
import MenuItem  from '@mui/material/MenuItem';
import GridItem from '../../components/requests/GridItem';
import { blue, red } from '@mui/material/colors';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Main(){
    const history = useHistory(); 
    const [display, setDisplay] = useState(0);
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    }
    
    function handleClick(){

    }

    function handleChange(){

    }

    
    

   

    return(
        <div>

            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                <Typography variant="h4" style={{fontFamily:"InterBold"}}>Requests</Typography>
                <Typography variant="body1" color="textSecondary">Manage search requests</Typography>
                </Grid>

                <Grid item>

                </Grid>
            </Grid>

            <div style={{marginTop:20}} />
            <Grid container direction="row">
              <Grid item lg={8} md={12}>
                
                <DataDisplay />
              </Grid>
              <Grid item md={4}>
              </Grid>
            </Grid>

           
        </div>
    )
}



function DataDisplay(props){
    const requests = useSelector(state => state.request.requests);
    const [page, setPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(8);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage - 1);
    const [data, setData] = useState(requests ? requests.slice().sort((a, b) =>
    new Date(a.created) < new Date(b.created) ? 1 : -1): [])

    useEffect(() => {
      setData(requests ? requests.slice().sort((a, b) =>
      new Date(a.created) < new Date(b.created) ? 1 : -1): [])
    }, [requests])

    const handlePageChange  = (event, value) => {
      setPage(value);
      setStartIndex(itemsPerPage*(value -1))
      setEndIndex(value*itemsPerPage - 1);
  }

    function handleFilter(searchText, location){
     
      if(location == "all"){
        setData(requests.filter((item) => item.state != location).filter(item => item.description.includes(searchText))) 
      }else{
        setData(requests.filter((item) => item.state == location).filter(item => item.description.includes(searchText)))
      }  
   }
    return(
        <>
        <Toolbar update={(search, location) => handleFilter(search, location)} />
        <div style={{marginTop:10}}/>
        <Header />
         {data.map((item, index) => (
          index >= startIndex && index <= endIndex &&
             <GridItem data={item} />
         ))}
          <div style={{marginTop:10, marginBottom:10, justifyContent:"center", display:"flex"}}>
         
          {data.length > 0 &&   <Pagination page={page} 
                onChange={handlePageChange}
                 count={Math.ceil(data.length/itemsPerPage)} shape="rounded" />}
          </div>
        </>
    )
}

function Toolbar(props){
  const [form, setForm] = useState({search: "", location:"all"});
  
  function handleChange(event){
    const {name, value} = event.target;
    if(name == "search"){
      props.update(value, form.location)
    }else{
      props.update(form.search, value)
    }
    setForm(form => ({...form, [name]: value}))
    
  }

  return(
    <Grid container direction="row" spacing={1}>
        <Grid item md={6}>
        <TextField
          required
          fullWidth
          id="search"
          name="search"
          label="Search"
          defaultValue=""
          onChange={handleChange}
          variant="filled"
        />
        </Grid>
        <Grid item md={4}>
        <TextField
          required
          fullWidth
          id="location"
          name="location"
          label="Location"
          select
          onChange={handleChange}
          defaultValue="all"
          variant="filled"
        >
          <MenuItem value={"all"}> All </MenuItem>
          <MenuItem value={"lagos"}> Lagos </MenuItem>
          <MenuItem value={"abuja"}> Abuja </MenuItem>
        </TextField>
        </Grid>
        <Grid item md={2}>
         
        </Grid>
      </Grid>
  )
}

function Header(){
  return(
    <ListItem divider disablePadding style={{paddingTop:10, paddingBottom:10}}>
        <Grid container direction="row"  spacing={1} alignItems={"center"} justifyContent={"space-between"}>
            <Grid item md={2}>
            <Typography variant="body1" style={{fontFamily:"InterBold"}}>  ID </Typography>
            </Grid>
            <Grid item md={2}>
            <Typography variant="body1" style={{fontFamily:"InterBold"}}> Location  </Typography>
            </Grid>
            <Grid item md={6}>
            <Typography variant="body1" style={{fontFamily:"InterBold"}}> Description  </Typography>
            </Grid>
            <Grid item md={2}>
            <Typography variant="body1" style={{fontFamily:"InterBold"}}> Posted  </Typography>
            </Grid>
        </Grid>
        </ListItem>
  )
}





