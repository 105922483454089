import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography  from '@mui/material/Typography';
import Breadcrumbs  from '@mui/material/Breadcrumbs';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {CopyToClipboard} from 'react-copy-to-clipboard';


import ConfirmationModal from '../../components/modals/Confirmation';
import About from '../../components/listing/About';

import { notify } from '../../actions';
import {updateListing, deleteListing} from '../../actions/listings';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Main(){
    const dispatch = useDispatch()
    const history = useHistory();
    const [confirm, setConfirm] = useState(false)
    const listing = useSelector(state => state.listing.listing);
    const [publish, setPublish] = useState(listing.published=="true" ? true : false);

    const [display, setDisplay] = useState("grid");
    const [filter, setFilter] = useState(0);
    const [tab, setTab] = useState(0);

 

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    }

    function handlePreview(){

    }

    useEffect(() => {
      console.log(listing)
    }, [])

    function handleCopy(){
      dispatch(notify({message:"Link copied successfully", status:"success"}))
    }


    function handleClick(){

    }

    function handlePublish(event){
      setPublish(event.target.checked);
      const form = {...listing, published:event.target.checked ? "true" : "false"}
      dispatch(updateListing(form))
    }

    function handleDelete(){
      dispatch(deleteListing(listing, history))
    }

    return(
        <div>
            <ConfirmationModal open={confirm} confirm={handleDelete} />
            <Grid container direction="row" justifyContent="space-between" alignItems={"center"}>
                <Grid item>
                <Typography variant="h6" style={{fontFamily:"UberBold"}}>{listing.title}</Typography>
                <Typography variant="body1" color="textSecondary">Edit and manage this property</Typography>
                </Grid>

                <Grid item>
                
               
                <ButtonGroup
                  disableElevation
                  variant="contained"
                  aria-label="Disabled elevation buttons"
                >
                <FormControlLabel control={<Switch checked={publish} onChange={handlePublish}  />} label="Publish" />
                 <IconButton color="primary" onClick={() => history.push("/listings/edit")} ><EditIcon  /></IconButton>
                 {/*<IconButton onClick={handlePreview} ><PreviewIcon  /></IconButton>*/}
                 <CopyToClipboard text={"Hello"} onCopy={handleCopy}>
                 <IconButton color="primary"><LinkIcon  /></IconButton>
                
                 </CopyToClipboard>
                 <IconButton onClick={() => setConfirm(true)} color="primary"><DeleteIcon  /></IconButton>
               
              
                </ButtonGroup>
                </Grid>
            </Grid>

            <div style={{marginTop:20}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="/listings">
                    Listings
                  </Link>
                  <Typography color="text.primary">{listing.title}</Typography>
                </Breadcrumbs>
              </div>
              </Grid>
            </Grid>  
            <Link href="#">{"https://listings.rentify.com/" + listing.url}</Link>
            </div>


            <div style={{marginTop:30}}>
        <Grid container direction="row">
       
        <Grid item lg={8} md={12}>
        <About />
        </Grid >

        <Grid item lg={4} md={4} >
        <Insights />
        </Grid>  
      </Grid> 
        </div>
             

            
                         
        </div>
    )
}




function Insights(){
  return(
    <div style={{marginTop:20}}>
          
    </div>
  )
}