import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography  from '@mui/material/Typography';
import TextField  from '@mui/material/TextField';

import ListItem  from '@mui/material/ListItem';
import Avatar  from '@mui/material/Avatar';
import ListItemIcon  from '@mui/material/ListItemIcon';
import Checkbox  from '@mui/material/Checkbox';
import Chip  from '@mui/material/Chip';
import MenuItem  from '@mui/material/MenuItem';
import Pagination  from '@mui/material/Pagination';
import Select  from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import AddIcon from '@mui/icons-material/Add';

import GridItem from '../../components/portfolio/GridItem';
import AddPortfolioModal from '../../components/portfolio/AddPortfolioModal';
import UploadAvatar from '../../components/image/onboarding/UploadAvatar';
import { useSelector } from 'react-redux';
import {updateProfile} from '../../actions/user';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Main(){
    const dispatch = useDispatch();
    const [display, setDisplay] = useState("grid");
    const [filter, setFilter] = useState(0);
    const [create, setCreate] = useState(false);
    const user = useSelector(state => state.user.user);
    const [form, setForm] = useState(user)


    function handleChange(event){
       const {name, value} = event.target;
       setForm(form => ({...form, [name]:value}));
    }


    function handleClick(){

        dispatch(updateProfile(form));
    }

  

    return(
        <div>
          
          <Grid container direction="row">
            <Grid item md={6}>
            <Typography variant="h4" style={{fontFamily:"InterBold"}}>Notifications</Typography>
            <Typography variant="body1" color="textSecondary">Manage your notifications</Typography>

            </Grid>
          </Grid>


          <div style={{marginTop:20}} />


            <Grid container direction="row">
              <Grid item md={8}>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                      <Grid item md={10}>
                        <TextField type="text" variant="filled" fullWidth label="Search" />
                      </Grid>
                    </Grid>


                    <div style={{marginTop:20}} />

    
                    <Item  />
                    <Item />
                    <Item  />
                    <Item />
                    <Item  />
                    <Item />
                    <Item  />
                    <Item />
                    <Item  />
                    <Item />

                    <div style={{marginTop:20}} />

                  <div style={{display:"flex", justifyContent:"center"}}>
                  <Pagination count={10} variant="outlined" shape="rounded" />
                  </div>
              </Grid>
            </Grid>         
        </div>
    )
}






function Item(){
  function handleClick(){

  }

  return(
     <ListItem button onClick={handleClick} divider >
        <Grid container direction="row" justifyContent="space-between" alignItems={"center"}>
          <Grid item md={1}>
            <Avatar />
          </Grid>
          <Grid item md={9}>
            <Typography variant="body1" style={{fontFamily:"InterBold"}}> Jane Doe  </Typography>
            <Typography variant="body2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </Typography>
          </Grid>
          
          <Grid item>
          <Typography variant="body1" color="textSecondary">2 mins ago</Typography>
          </Grid>
        </Grid>
     </ListItem>
  )
}