import {axiosHandler, defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_LISTING, FETCH_REALTOR} from '../constants/onboard';

import * as validator from '../validators/portfolio';

import {setUser, loggedIn} from './user';


export function onBoard(body, props){
    const url = '/realtor/onboard';
    return async dispatch => {
      dispatch(wait(true));
      var [status, message] = validator.create(body);
      if(status == "success"){
        var [res, status] = await defaultHandler(url, body);
        console.log(res);
        if(res.status == "success"){
          dispatch(notify({message: "Account created successfully", status:"success"}));
          dispatch(fetchListing(res.response.onboarding.listing))
          dispatch(loggedIn(true))
          dispatch(setUser(res.response.onboarding.realtor));
          props.complete();
        }else{
          dispatch(notify({message: res.message, status:"error"}));
          //dispatch(unexpectedError())
        }
      }else{
        dispatch(notify({message:message, status:"error", show: true}));  
      }
      dispatch(wait(false))
    }
        
}


export function fetchRealtor(payload){
    return { type: FETCH_REALTOR, payload }
}
  
export function fetchListing(payload){
    return { type: FETCH_LISTING, payload }
}

