import { combineReducers } from "redux";
import {
  INITIALIZE,
  UNEXPECTED_ERROR,
  WAIT,
  CLEAR_NOTIFY,
  NOTIFY,
  PROFILE_UPDATE,
} from "../constants/index";
import portfolioReducer from "./portfolio";
import listingReducer from "./listings";
import onboardReducer from "./onboard";
import userReducer from "./user";
import requestReducer from "./requests";
import reviewReducer from "./reviews";
import artifactReducer from "./artifacts";
import suggestionReducer from "./suggestions";

const initialState = {
  loading: false,
  notify: {
    message: "",
    status: "",
    open: false,
  },
  content: "Starboard",
};

function mainReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE:
      return Object.assign({}, state, {
        loading: false,
        loggedIn: false,
      });
    case UNEXPECTED_ERROR:
      return Object.assign({}, state, {
        notify: {
          message: "unexpected error occured",
          status: "error",
        },
      });
    case WAIT:
      return Object.assign({}, state, {
        loading: action.payload,
      });
    case NOTIFY:
      return Object.assign({}, state, {
        notify: {
          ...action.payload,
          open: true,
        },
      });
    case CLEAR_NOTIFY:
      return Object.assign({}, state, {
        notify: {
          open: false,
          message: "",
          status: "",
        },
      });
  }
  return state;
}

const rootReducer = combineReducers({
  app: mainReducer,
  artifacts: artifactReducer,
  user: userReducer,
  portfolio: portfolioReducer,
  listing: listingReducer,
  onboard: onboardReducer,
  request: requestReducer,
  review: reviewReducer,
  suggestion: suggestionReducer,
});

export default rootReducer;
