import * as React from 'react';
import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';


import Default from './Default';
import Request from './Request';
import FooterMenu from '../../components/mobile/page/FooterMenu';

import {getRequests} from '../../actions/requests';

export default function BottomAppBar() {
  
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector(state => state.user.user);
  const [open, setOpen] = React.useState(true);
  const [screen, setScreen] = useState("default");

  let { page } = useParams();


  useEffect(() => {
    console.log(page)
    setScreen(page == null ? "default" : page)
  }, [page])

  useEffect(() => {
    dispatch(getRequests())
  }, [])


  return (
    <React.Fragment>
      <CssBaseline />

      <div style={{padding:5}}>
      {getScreen(screen)}
      </div>
     
      <div style={{marginTop:150}}/>
      <FooterMenu/>
      
    </React.Fragment>
  );
}

function getScreen(screen){
  switch(screen){
    case "default":
      return (<Default  />)
    case "request":
      return (<Request  />)
  }
}