import {LOGGED_IN, SET_USER} from '../constants/user';
import { defaultHandler } from '../requests/global';
import {wait, unexpectedError, notify} from './index';
import * as validator from '../validators/user';


export function setUser(payload){
  return { type: SET_USER, payload }
}

export function loggedIn(payload){
  return { type: LOGGED_IN, payload }
}


export function createUser(body, history){
    return async dispatch =>  {
      const url = '/realtor/create';
      const [status, message] = validator.register(body);
        dispatch(wait(true));
        if(status == "success"){
          const [res, status] = await defaultHandler(url, body);
          console.log(res);
          if (status == "ok"){
            if(res.status == "success"){
              if(res.response.vendor){
                dispatch(setUser(res.response.vendor[0]))
                history.push("/register/auth")
              }
            }else{
              dispatch(notify({message: res.message, status: "error"}))
            }
          }else{
              dispatch(unexpectedError());
          }
        }else{
          dispatch(notify({message: message, status: "error"}))
        }
        dispatch(wait(false));
    };
}

export function findUser(body, props){
  return async dispatch =>  {
    const url = '/realtor/find';
      dispatch(wait(true));
      if(body.user != null){
        const [res, status] = await defaultHandler(url, body);
        console.log(res);
        if (status == "ok"){
          if(res.status == "success"){
            dispatch(setUser(res.response.realtor))
            dispatch(loggedIn(true))
            props.switch();
          }else{
            dispatch(notify({message: "No account exist with that email address or phone number", status: "error"}))
          }
        }else{
            dispatch(unexpectedError());
        }
      }else{
        dispatch(notify({message:"please provide a username", status: "error"}))
      }
      dispatch(wait(false));
  };
}

export function loginUser(body, history){
  return async dispatch =>  {
    const url = '/realtor/find';
      dispatch(wait(true));
      if(body.user != null){
        const [res, status] = await defaultHandler(url, body);
        console.log(res);
        if (status == "ok"){
          if(res.status == "success"){
            if(res.response.vendor.name != null){
              dispatch(setUser(res.response.vendor))
              history.push("/login/auth");
            }else{
              dispatch(notify({message: "Sorry, we couldn't find that account", status: status}))
            }
          }else{
            dispatch(notify({message: res.message, status: "error"}))
          }
        }else{
            dispatch(unexpectedError());
        }
      }else{
        dispatch(notify({message:"please provide a username", status: "error"}))
      }
      dispatch(wait(false));
  };
}

export function updateProfile(body, history){
  return async dispatch =>  {
    const url = "/realtor/profile/update";
    dispatch(wait(true));
    var [status, message] = validator.profile(body);
    if(status == "success"){
      var [res, status] = await defaultHandler(url, body);
      if (status === "ok"){
        if(res){
          console.log(res)
          dispatch(setUser(res.response.realtor[0]));  
          dispatch(notify({message: "Profile updated successfully", status: "success"}))
        }else{
          dispatch(unexpectedError());
        }
      }else{
          dispatch(unexpectedError())}
    }else{
        dispatch(notify({message: message, status: status}));
    }
    dispatch(wait(false));
  }
}

export function updateWelcomeProfile(body, history){
  return async dispatch =>  {
    const url = "/vendor/profile/update";
    dispatch(wait(true));
    var [status, message] = validator.profile(body);
    console.log(body);
    if(status == "success"){
      var [res, status] = await defaultHandler(url, body);
      if (status === "ok"){
        if(res){
          dispatch(setUser(res.response.vendor[0]));  
          dispatch(notify({message: "Profile updated successfully", status: "success"}))
          history.push("/welcome")
        }else{
          dispatch(unexpectedError());
        }
      }else{
          dispatch(unexpectedError())}
    }else{
        dispatch(notify({message: message, status: status}));
    }
    dispatch(wait(false));
  }
}

export function updateSurvey(body, history){
  return async dispatch =>  {
    const url = "/vendor/profile/update";
    dispatch(wait(true));
    var [status, message] = validator.survey(body);
    if(status == "success"){
      var [res, status] = await defaultHandler(url, body);
      if (status === "ok"){
        if(res){
          dispatch(setUser(res.response.vendor[0]));  
          dispatch(notify({message: "Profile updated successfully", status: "success"}))
          history.push("/welcome")
        }else{
          dispatch(unexpectedError());
        }
      }else{
          dispatch(unexpectedError())}
    }else{
        dispatch(notify({message: message, status: status}));
    }
    dispatch(wait(false));
  }
}



export function completeOnboarding(body, history){
  return async dispatch =>  {
    const url = "/vendor/profile/boarded";
    dispatch(wait(true));
    
      var [res, status] = await defaultHandler(url, body);
      if (status === "ok"){
        if(res){
          console.log(res)
          dispatch(setUser(res.response.vendor[0]));  
          dispatch(notify({message: "Your onboarding process is complete", status: "success"}))
          history.push("/dashboard");
        }else{
          dispatch(unexpectedError());
        }
      }else{
          dispatch(unexpectedError())
      }
    
    dispatch(wait(false));
  }
}






