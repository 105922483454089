import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Typography  from '@mui/material/Typography';
import NewListing from './NewListing';

export default function Main(props){
    const [open, setOpen] = useState(props.open);
    const dispatch = useDispatch()

    function handleClose(){
        props.close()
    }

    function handleSubmit(){
      
    }

    useEffect(() => {
      setOpen(props.open)
    }, [props.open])

  
    return(
        <Dialog
        open={open}
        fullScreen
        
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
           
            <Typography sx={{ ml: 2, flex: 1 }} fontFamily={"InterBold"} variant="h6" component="div">
              New Listing
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSubmit}>
               <Typography variant="body1" fontFamily={"SpaceMonoBold"}>Save</Typography> 
            </Button>
           
           
          </Toolbar>
        </AppBar>
       
        <DialogContent style={{justifyContent:"center"}}>
        <Grid container direction="row" justifyContent="center">
          <Grid item md={8} >
          <NewListing />
          </Grid>
        </Grid>
          
        </DialogContent>
       
      </Dialog>
    )
}