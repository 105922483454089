import {FETCH_LISTING, FETCH_REALTOR} from  "../constants/onboard";


const initialState = {
  property:{
    title: "",
    location: "",
    description: "",
    bedrooms: "",
    bathrooms: "",
    kitchens: '',
    total_area: "",
    property_type: ""
  },
  realtor:{
    name: "",
    phone: "",
    email: "",
    company_name: "",
    company_reg: "",
    company_address: "",
    about: "",
  }
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_LISTING:
      return Object.assign({}, state, {
         listing: action.payload
       });
    case FETCH_REALTOR:
      return Object.assign({}, state, {
         realtor: action.payload
      });
  }
  return state;
}

export default reducer;
