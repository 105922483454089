import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  Pie,
  Cell,
  PieChart,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from "recharts";
import { IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import RatingChart from "../../components/web/reviews/Chart";

import Listings from "../../components/dashboard/Listings";
import Appointments from "../../components/dashboard/Appointments";
import Stats from "../../components/dashboard/Stats";
import Messages from "../../components/dashboard/Messages";
import { averageRating, ratingDistribution } from "../../functions/chart";
import { getReviews } from "../../actions/reviews";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const data = [
  {
    name: "January",
    views: 4000,
  },
  {
    name: "February",
    views: 3000,
  },
  {
    name: "March",
    views: 2000,
  },
  {
    name: "April",
    views: 2780,
  },
  {
    name: "May",
    views: 1890,
  },
  {
    name: "June",
    views: 2390,
  },
  {
    name: "July",
    views: 3490,
  },
];

const data_bar = [
  {
    name: "Jan",
    short: 4000,
    long: 2400,
  },
  {
    name: "Feb",
    short: 3000,
    long: 1398,
  },
  {
    name: "Mar",
    short: 2000,
    long: 9800,
  },
  {
    name: "Apr",
    short: 2780,
    long: 3908,
  },
  {
    name: "Jun",
    short: 1890,
    long: 4800,
  },
  {
    name: "Jul",
    short: 2390,
    long: 3800,
  },
  {
    name: "Aug",
    short: 3490,
    long: 4300,
  },
];

const data01 = [
  {
    name: "Group A",
    value: 400,
  },
  {
    name: "Group B",
    value: 300,
  },
  {
    name: "Group C",
    value: 300,
  },
  {
    name: "Group D",
    value: 200,
  },
  {
    name: "Group E",
    value: 278,
  },
  {
    name: "Group F",
    value: 189,
  },
];
const data02 = [
  {
    name: "Group A",
    value: 2400,
  },
  {
    name: "Group B",
    value: 4567,
  },
  {
    name: "Group C",
    value: 1398,
  },
  {
    name: "Group D",
    value: 9800,
  },
  {
    name: "Group E",
    value: 3908,
  },
  {
    name: "Group F",
    value: 4800,
  },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function Main() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const reviews = useSelector((state) => state.review.reviews);
  const pieData = ratingDistribution(reviews);
  useEffect(() => {
    const body = { id: user.id };
    dispatch(getReviews(body));
  }, []);
  return (
    <div>
      <div>
        <Typography variant="h4" style={{ fontFamily: "InterBold" }}>
          Hello, {user.name}
        </Typography>
        <Link href={"https://my.rentage.app/" + user.url}>
          <Typography variant="body1" color="textSecondary">
            {"https://my.rentage.app/" + user.url}
          </Typography>
        </Link>
      </div>

      <div style={{ marginTop: 20 }}>
        <Grid container direction="row" spacing={2}>
          <Grid item md={8}>
            <div style={{ height: 300, width: "100%" }}>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ fontFamily: "InterBold" }}
              >
                {" "}
                VIEWS{" "}
              </Typography>
              <div style={{ marginTop: 10 }} />
              <ResponsiveContainer width={"100%"} height="80%">
                <AreaChart
                  data={data}
                  margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="views"
                    stroke="#000000"
                    fill="#000000"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>

            <div style={{ height: 300, width: "100%" }}>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ fontFamily: "InterBold" }}
              >
                {" "}
                LISTINGS{" "}
              </Typography>
              <div style={{ marginTop: 10 }} />
              <ResponsiveContainer width={"100%"} height="80%">
                <BarChart width={"100%"} height={250} data={data_bar}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="short" fill="#000000" radius={[10, 10, 0, 0]} />
                  <Bar dataKey="long" fill="#00C49F" radius={[10, 10, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <Grid container direction="row">
              <Grid item md={6}>
                <Typography
                  align="center"
                  variant="body1"
                  style={{ fontFamily: "InterBold" }}
                  color="textSecondary"
                >
                  Listings by Category{" "}
                </Typography>
                <PieChart width={730} height={250}>
                  <Pie
                    data={data01}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                    <Legend />
                  </Pie>
                </PieChart>
              </Grid>
              <Grid item md={6}>
                <Typography
                  align="center"
                  variant="body1"
                  style={{ fontFamily: "InterBold" }}
                  color="textSecondary"
                >
                  Listings by Location{" "}
                </Typography>
                <PieChart width={730} height={250}>
                  <Pie
                    data={data01}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                    <Legend />
                  </Pie>
                </PieChart>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div style={{ justifyContent: "center" }}>
                <Typography
                  align="center"
                  variant="h6"
                  color="textSecondary"
                  style={{ fontFamily: "InterBold" }}
                >
                  OVERALL RATING
                </Typography>
                <Typography
                  align="center"
                  variant="h4"
                  style={{ fontFamily: "SpaceBold" }}
                >
                  {averageRating(pieData)}
                </Typography>
              </div>
            </div>
            <div style={{ height: 300, width: "100%" }}>
              <RatingChart data={pieData} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function Header() {
  return <div></div>;
}
