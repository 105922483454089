import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography  from '@mui/material/Typography';
import TextField  from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab';
import ListItem  from '@mui/material/ListItem';
import Alert from '@mui/material/Alert';
import Avatar  from '@mui/material/Avatar';
import ListItemIcon  from '@mui/material/ListItemIcon';
import Checkbox  from '@mui/material/Checkbox';
import Chip  from '@mui/material/Chip';
import MenuItem  from '@mui/material/MenuItem';
import Select  from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import AddIcon from '@mui/icons-material/Add';

import GridItem from '../../components/portfolio/GridItem';
import AddPortfolioModal from '../../components/portfolio/AddPortfolioModal';
import UploadAvatar from '../../components/image/onboarding/UploadAvatar';
import { useSelector } from 'react-redux';
import {updateProfile} from '../../actions/user';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Main(){
    const dispatch = useDispatch();
    const [display, setDisplay] = useState("grid");
    const [filter, setFilter] = useState(0);
    const [create, setCreate] = useState(false);
    const user = useSelector(state => state.user.user);
    const [form, setForm] = useState(user)
    const [tab, setTab] = useState(0)


    function handleChange(event){
       const {name, value} = event.target;
       setForm(form => ({...form, [name]:value}));
    }

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };


    function handleClick(){

        dispatch(updateProfile(form));
    }

  

    return(
        <div>
          
          <Grid container direction="row">
            <Grid item md={6}>
            <Typography variant="h4" style={{fontFamily:"InterBold"}}>Billings</Typography>
            <Typography variant="body1" color="textSecondary">Manage your billings</Typography>

            </Grid>
          </Grid>
            
              
            <div style={{marginTop:20}} />

            <Grid container direction="row" spacing={2} justifyContent={"space-between"}>
              <Grid item md={6}>
                <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                  <Tab label={<Typography variant="body1" style={{fontFamily:"InterBold"}}> Invoices </Typography>} {...a11yProps(0)} />
                  <Tab label={<Typography variant="body1" style={{fontFamily:"InterBold"}}> History </Typography>} {...a11yProps(1)} />

                 
                </Tabs>

                <div style={{marginTop:20}} />
                <TextField type="text" variant="filled" fullWidth label="Search" />
                <div style={{marginTop:20}} />
                <InvoiceHeader />
                <InvoiceItem />
                <InvoiceItem />
                <InvoiceItem />
                <InvoiceItem />
                <InvoiceItem />
                <InvoiceItem />
                <InvoiceItem />
                <InvoiceItem />
                <InvoiceItem />

                <div style={{marginTop:10}} />

                <div style={{display:"flex", justifyContent:"center"}}>
                  <Pagination count={10} variant="outlined" shape="rounded" />
                </div>
              </Grid>
              <Grid item md={4}>
                <Typography variant="h6" color="textSecondary" style={{fontFamily:"InterBold"}}>Subscription</Typography>
                <div style={{marginTop:5}} />
                <Alert severity="warning">Your subscription expires on Aug 2024.</Alert>
                <div style={{marginTop:5}} />
                <Typography variant="body1"><b>License</b></Typography>
                <Typography variant="body1">Premium</Typography>

                <div style={{marginTop:20}} />

                <Typography variant="h6" color="textSecondary" style={{fontFamily:"InterBold"}}>Payment Methods</Typography>
                <div style={{marginTop:5}} />

                <Typography variant="body1"><b>Credit Card</b></Typography>
                <Typography variant="body1">Mastercard ****0000 | Exp 02/2025</Typography>


                <div style={{marginTop:20}} />
                <Typography variant="h6" color="textSecondary" style={{fontFamily:"InterBold"}}>Receipt Details</Typography>
                <div style={{marginTop:5}} />

                <Typography variant="body1"><b>Tax ID</b></Typography>
                <Typography variant="body1">1234567</Typography>

                <div style={{marginTop:5}} />

                <Typography variant="body1"><b>Billing Address</b></Typography>
                <Typography variant="body1">North Lane, XYZ Street</Typography>

                <div style={{marginTop:20}} />
                <Typography variant="h6" color="textSecondary" style={{fontFamily:"InterBold"}}>Billing Email Recipients</Typography>
                <div style={{marginTop:5}} />
                
                <Typography variant="body1"><b>Account Admins</b></Typography>
                <Typography variant="body1">mojeed.oyedeji@gmail.com</Typography>

              </Grid>
            </Grid>
           

          
            
            
            
            

            
           
           
              

               

           
            
                 
        </div>
    )
}



function InvoiceHeader(){
  return(
    <ListItem divider disablePadding style={{paddingTop:10, paddingBottom:10}}>
        <Grid container direction="row"  spacing={1} alignItems={"center"} justifyContent={"space-between"}>
            <Grid item md={2}>
            <Typography variant="body1"  color="textSecondary" style={{fontFamily:"InterBold"}}> Status </Typography>
            </Grid>
            <Grid item md={4}>
            <Typography variant="body1" color="textSecondary" style={{fontFamily:"InterBold"}}> Invoice #  </Typography>
            </Grid>
            <Grid item md={2}>
            <Typography variant="body1" color="textSecondary" style={{fontFamily:"InterBold"}}> Issued </Typography>
            </Grid>
            <Grid item md={2}>
            <Typography variant="body1" color="textSecondary" style={{fontFamily:"InterBold"}}> Due  </Typography>
            </Grid>
        </Grid>
        </ListItem>
  )
}

function HistoryHeader(){
  return(
    <ListItem divider disablePadding style={{paddingTop:10, paddingBottom:10}}>
        <Grid container direction="row"  spacing={1} alignItems={"center"} justifyContent={"space-between"}>
            <Grid item md={2}>
            <Typography variant="body1"  color="textSecondary" style={{fontFamily:"InterBold"}}> Status </Typography>
            </Grid>
            <Grid item md={4}>
            <Typography variant="body1" color="textSecondary" style={{fontFamily:"InterBold"}}> Invoice #  </Typography>
            </Grid>
            <Grid item md={2}>
            <Typography variant="body1" color="textSecondary" style={{fontFamily:"InterBold"}}> Issued </Typography>
            </Grid>
            <Grid item md={2}>
            <Typography variant="body1" color="textSecondary" style={{fontFamily:"InterBold"}}> Due  </Typography>
            </Grid>
        </Grid>
        </ListItem>
  )
}


function InvoiceItem(){
  return(
    <ListItem divider disablePadding style={{paddingTop:10, paddingBottom:10}}>
        <Grid container direction="row"  spacing={1} alignItems={"center"} justifyContent={"space-between"}>
            <Grid item md={2}>
            <Chip color="success" 
            label={<Typography color="success" variant="body2"> Status </Typography>} />
            
            </Grid>
            <Grid item md={4}>
            <Typography variant="body1"> ARK123456  </Typography>
            </Grid>
            <Grid item md={2}>
            <Typography variant="body1"> Jun 23, 2023 </Typography>
            </Grid>
            <Grid item md={2}>
            <Typography variant="body1"> Jun 23, 2024 </Typography>
            </Grid>
        </Grid>
        </ListItem>
  )
}


function HistoryItem(){
  return(
    <ListItem divider disablePadding style={{paddingTop:10, paddingBottom:10}}>
        <Grid container direction="row"  spacing={1} alignItems={"center"} justifyContent={"space-between"}>
            <Grid item md={2}>
            <Typography variant="body1"  color="textSecondary" style={{fontFamily:"InterBold"}}> Status </Typography>
            </Grid>
            <Grid item md={2}>
            <Typography variant="body1" color="textSecondary" style={{fontFamily:"InterBold"}}> Invoice #  </Typography>
            </Grid>
            <Grid item md={6}>
            <Typography variant="body1" color="textSecondary" style={{fontFamily:"InterBold"}}> Issued </Typography>
            </Grid>
            <Grid item md={2}>
            <Typography variant="body1" color="textSecondary" style={{fontFamily:"InterBold"}}> Due  </Typography>
            </Grid>
        </Grid>
        </ListItem>
  )
}




