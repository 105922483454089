import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import AssetCardMedia from '../../components/image/portfolio/AssetCardMedia';


import {fetchRequest} from '../../actions/requests';


export default function Main(props){
    const dispatch = useDispatch();
    const history = useHistory();

   
    function handleClick(){
        dispatch(fetchRequest(props.data))
        history.push("/requests/request")
    }

    return(
        <ListItem button onClick={handleClick} divider disablePadding style={{paddingTop:10, paddingBottom:10}}>
            <Grid container direction="row" alignItems="center" spacing={1} justifyContent={"space-between"}>
                <Grid item md={2}>
                <Typography variant="body1">{props.data.id.substr(0,9)}</Typography>
                <Chip size="small" style={{borderRadius:0}}
                 color="warning" label={<Typography variant="body2">{props.data.status}</Typography>} />
                </Grid>
              
                <Grid item md={2}>
                <Typography variant="body1">{props.data.state.charAt(0).toUpperCase() + props.data.state.slice(1)}</Typography>
                <Typography variant="body1">{props.data.location}</Typography>
                </Grid>
                <Grid item md={6}>
                <Typography variant="body1">{props.data.bedroom} {"Bedroom(s)"}</Typography>
                <Typography variant="body1" color="textSecondary" noWrap>{props.data.description.substr(0,100)}</Typography>
                </Grid>
                <Grid item md={2}>
                <Typography variant="body1">{props.data.created.substr(0,10)}</Typography>
                <Typography variant="body1">{props.data.created.substr(11,5)}</Typography>
                </Grid>
            </Grid>
        </ListItem>
    )
}