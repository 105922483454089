import {axiosHandler, defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_SUGGESTION, FETCH_SUGGESTIONS} from '../constants/suggestions';




export function getSuggestions(body){
  const url = '/realtor/suggestions/realtor';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, body);
      if(status == "ok"){
        if(res.response != null){
          dispatch(fetchSuggestions(res.response.suggestions));
        }else{

        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}


export function getMySuggestion(body){
  const url = '/realtor/suggestions/realtor';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, body);
      console.log(res)
      if(status == "ok"){
        if(res.response != null){
          dispatch(fetchSuggestions(res.response.suggestions));
        }else{

        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}

export function addSuggestion(body){
    const url = '/realtor/suggestions/create';
    return async dispatch => {
      dispatch(wait(true));
        var [res, status] = await defaultHandler(url, body);
        console.log(res);
        if(status == "ok"){
          if(res.response != null){
            dispatch(fetchSuggestions(res.response.suggestions));
          }else{
          }
        }else{
          dispatch(unexpectedError())
        }
      dispatch(wait(false))
    }     
}



export function removeSuggestion(body){
  const url = '/realtor/suggestions/delete';
  return async dispatch => {
    dispatch(wait(true));
      var [res, status] = await defaultHandler(url, body);
      console.log(res);
      if(status == "ok"){
        if(res.response != null){
          dispatch(fetchSuggestions(res.response.suggestions));
          //dispatch(notify({message:"Listing updated successfully", status:"success"}))
          //history.push("/listings")
        }else{

        }
      }else{
        dispatch(unexpectedError())
      }
    dispatch(wait(false))
  }     
}


export function fetchSuggestion(payload){
  return { type: FETCH_SUGGESTION, payload }
}

export function fetchSuggestions(payload){
  return { type: FETCH_SUGGESTIONS, payload }
}