import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import AssetImage from '../image/onboarding/AssetImage';

import {updateListing} from '../../../actions/listings';
export default function Main(props){
    const dispatch = useDispatch();
    const listing = useSelector(state => state.listing.listing);
    const [form, setForm] = useState(listing);

    function handleChange(event){
        const {name, value} = event.target;
        setForm(form => ({...form, [name]:value}));
    }

    function handleSave(){
        console.log(form);
        dispatch(updateListing(form));
    }

    return(
        <div>
    
        <div>
        <TextField name="title" fullWidth size="small" onChange={handleChange} 
        defaultValue={form.title} variant='filled' label="Listing Name" />
        </div>

        <div style={{marginTop:10}}>
        <TextField name="address" fullWidth size="small" variant='filled'
        onChange={handleChange} defaultValue={form.address} label="Location" />
        </div>


       
        

        
        <div style={{marginTop:10}}>
        <TextField name="description" variant='filled' multiline rows={4} fullWidth onChange={handleChange}
         defaultValue={form.description} size="small" label="Description" />
        </div>

       

        

        <div style={{marginTop:10}}>
            <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item xs={4}>
                <TextField label="State" 
                name="state"
                select
                variant='filled'
                onChange={handleChange}
                defaultValue={form.state}
                size="small"
                 fullWidth >
                <MenuItem value="lagos"> Lagos </MenuItem>
                <MenuItem value="abuja"> Abuja </MenuItem>
                </TextField>
                </Grid>
                <Grid item xs={8}>
                <TextField label="Region/Location" 
                name="location"
                variant='filled'
                onChange={handleChange}
                defaultValue={form.location}
                size="small"
                 fullWidth />
                </Grid>
                <Grid item xs={12}>
                <TextField label="Price" 
                name="price"
                variant='filled'
                onChange={handleChange}
                defaultValue={form.price}
                size="small"
                 fullWidth />
                </Grid>
            </Grid>
            </div>

            <div style={{marginTop:10}}>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={6}>
                <TextField 
                label="Living Rooms" 
                name="livingrooms"
                variant='filled'
                size="small"
                onChange={handleChange}
                defaultValue={form.livingrooms}
                 fullWidth />
                </Grid>
                <Grid item xs={6}>
                <TextField 
                label="Bedrooms" 
                name="bedrooms"
                variant='filled'
                size="small"
                onChange={handleChange}
                defaultValue={form.bedrooms}
               fullWidth /> 
            
                </Grid>
            </Grid>
            
            </div>
            
            <div style={{marginTop:10}}>
            <Grid container direction="row" spacing={1}>
                
                <Grid item xs={6}>
                <TextField 
                label="Bathrooms" 
                name="bathrooms"
                size="small"
                variant='filled'
                onChange={handleChange}
                defaultValue={form.bathrooms}
                 fullWidth />
                </Grid>
                <Grid item xs={6}>
                <TextField label="Kitchens" 
                name="kitchens"
                variant='filled'
                size="small"
                onChange={handleChange}
                defaultValue={form.kitchens}
                 fullWidth />
                </Grid>
              
            </Grid>
            
            </div>

          

            <div style={{marginTop:10}}>
            <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
                <TextField 
                label="Garages" 
                name="garages"
                size="small"
                variant='filled'
                onChange={handleChange}
                defaultValue={form.garages}
                 fullWidth />
                </Grid>
                <Grid item xs={6}>
                <TextField label="Total Area (sqft)" 
                name="total_area"
                size="small"
                variant='filled'
                onChange={handleChange}
                defaultValue={form.total_area}
                 fullWidth />
                </Grid>
            </Grid>
           
            </div>
           
            

           
            
           
           
           
            <div style={{marginTop:10}}>
            <Typography variant="body2" color="textSecondary">Please upload at least two images</Typography>
            <div style={{marginTop:10}} />
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={6}>
                        <AssetImage uri={form.image1} onComplete={(value) => setForm((form) => ({...form, image1: value}))} />
                    </Grid>
                    <Grid item xs={6}>
                        <AssetImage uri={form.image2} onComplete={(value) => setForm((form) => ({...form, image2: value}))} />
                    </Grid>
                    <Grid item xs={6}>
                        <AssetImage uri={form.image3} onComplete={(value) => setForm((form) => ({...form, image3: value}))} />
                    </Grid>
                    <Grid item xs={6}>
                        <AssetImage uri={form.image4} onComplete={(value) => setForm((form) => ({...form, image4: value}))} />
                    </Grid>
                    <Grid item>
                    <IconButton>
                        
                    </IconButton>
                    </Grid>
            </Grid>
            </div>

            <div style={{marginTop:10, marginBottom:20}}>
            <Button fullWidth onClick={handleSave} variant="contained" size="large" startIcon={<SaveIcon />}>
                <Typography variant="body1">Save</Typography>
            </Button>
            </div>
            

        
        
        </div>
    )
}