import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography  from '@mui/material/Typography';
import TextField  from '@mui/material/TextField';
import MenuItem  from '@mui/material/MenuItem';

import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import AddIcon from '@mui/icons-material/Add';

import GridItem from '../../components/listing/GridItem';

import { blue, red } from '@mui/material/colors';
import {getListings} from '../../actions/listings';


import AddModal from '../../components/web/listings/AddListing';

export default function Main(props){
    const history = useHistory();
    const portfolio = useSelector(state => state.portfolio.portfolio); 
    const [display, setDisplay] = useState(0);
    const [tab, setTab] = useState(0);
    const [modal, setModal] = useState(false);
    const listings = useSelector(state => state.listing.listings);
    const [data, setData] = useState(listings ? listings : []);
    const user = useSelector(state => state.user.user);
    
    const dispatch = useDispatch();
  
    useEffect(() => {
      setData(listings ? listings : [])
    }, [listings])
  
  
    useEffect(() => {
      const body = {realtor: user.id}
      dispatch(getListings(body))
    }, [])
  
    function handleFilter(searchText, location){
       
      if(location == "all"){
        setData(listings.filter((item) => item.state != location).filter(item => item.title.includes(searchText))) 
      }else{
        setData(listings.filter((item) => item.state == location).filter(item => item.title.includes(searchText)))
      }  
   }
  


    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    }
    
    function handleClick(){
      setModal(true)
      //history.push("/listings/add")
    }


  
    return(
        <div>
            <AddModal open={modal} close={() => setModal(false)} />
            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                <Typography variant="h4" style={{fontFamily:"InterBold"}}>Listings</Typography>
                <Typography variant="body1" color="textSecondary">Create and manage your listings</Typography>
                </Grid>

                <Grid item>
                <Button onClick={handleClick} startIcon={<AddIcon />} variant="contained">
                  <Typography variant="body1" style={{fontFamily:"InterBold"}}>Add Listing</Typography>
                </Button>
                </Grid>
            </Grid>

        <div style={{marginTop:30}}>
        <Grid container direction="row">
        
        <Grid item md={12} lg={8}>
        <Toolbar update={(search, location) => handleFilter(search, location)} />
        <div style={{marginTop:10}}/>
        <Grid container direction="row" spacing={1}>
        {data.map((item) => (
            <Grid item md={props.open ? 6: 4} lg={4}>
            <GridItem data={item} />
            </Grid>
        ))}
        </Grid>
        
       
          
        </Grid>
      </Grid> 
        </div>
               

            
            
                 
        </div>
    )
}




function Toolbar(props){
  const [form, setForm] = useState({search: "", location:"all"});


  function handleChange(event){
    const {name, value} = event.target;
    if(name == "search"){
      props.update(value, form.location)
    }else{
      props.update(form.search, value)
    }
    setForm(form => ({...form, [name]: value}))
  }

  return(
    <Grid container direction="row" spacing={1}>
        <Grid item md={6}>
        <TextField
          required
          fullWidth
          id="search"
          name="search"
          label="Search"
          defaultValue=""
          onChange={handleChange}
          variant="filled"
        />
        </Grid>
        <Grid item md={4}>
        <TextField
          required
          fullWidth
          id="location"
          name="location"
          label="Location"
          select
          onChange={handleChange}
          defaultValue="all"
          variant="filled"
        >
          <MenuItem value={"all"}> All </MenuItem>
          <MenuItem value={"lagos"}> Lagos </MenuItem>
          <MenuItem value={"abuja"}> Abuja </MenuItem>
        </TextField>
        </Grid>
        <Grid item md={2}>
         
        </Grid>
      </Grid>
  )
}