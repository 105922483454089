import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import Login from "./web/account/Login";
import Auth from "./web/account/Auth";
import Dashboard from "./web/dashboard/Main";
import Onboard from "./web/onboard/Main";
import Requests from "./web/requests/Main";
import Listing from "./web/listings/Main";
import Profile from "./web/profile/Main";
import Billings from "./web/billings/Main";
import Reviews from "./web/reviews/Main";
import Links from "./web/links/Main";
import Notifications from "./web/notifications/Main";
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 1025 });
  return isNotMobile ? children : null;
};

export default function Main() {
  const user = useSelector((state) => state.user);
  return (
    <Default>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/onboard" component={Onboard} />
        <Route exact path="/auth" component={Auth} />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/login"
          component={Login}
        />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/profile"
          component={Profile}
        />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/dashboard/:page"
          component={Dashboard}
        />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/dashboard"
          component={Dashboard}
        />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/requests/:page"
          component={Requests}
        />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/requests"
          component={Requests}
        />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/listings/:page"
          component={Listing}
        />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/listings"
          component={Listing}
        />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/billings"
          component={Billings}
        />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/reviews"
          component={Reviews}
        />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/links"
          component={Links}
        />
        <PrivateRoute
          loggedIn={user.loggedIn}
          exact
          path="/notifications"
          component={Notifications}
        />
      </Switch>
    </Default>
  );
}

const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loggedIn ? <Component {...props} /> : <Redirect to={{ pathname: "/" }} />
    }
  />
);
