import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import GridItem from '../listing/GridItem';
import Typography  from '@mui/material/Typography';

export default function Main(){
    const listings = useSelector(state => state.listing.listings);
    const [data, setData] = useState(listings ? listings : []);


    useEffect(() => {
        setData(listings ? listings : []);
    }, [listings])


    

    return(
        <div>
            <Typography variant="body1"><b>Featured Listings</b></Typography>
            <div style={{marginTop:20}} />
            <Grid container direction="row" spacing={1}>
                {data.map((item) => (
                    <Grid item md={4}>
                        <GridItem data={item} />
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}