import {FETCH_REQUEST, FETCH_REQUESTS, FETCH_LISTINGS} from  "../constants/requests";


const initialState = {
  request:{
  },
  requests:[],
  listings: []
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
         request: action.payload
       });
    case FETCH_REQUESTS:
      return Object.assign({}, state, {
         requests: action.payload
      });
    case FETCH_LISTINGS:
      return Object.assign({}, state, {
          listings: action.payload
      });
  }
  return state;
}

export default reducer;
