import React from 'react';
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography  from '@mui/material/Typography';

import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GridViewIcon from '@mui/icons-material/GridView';

import ShareIcon from '@mui/icons-material/Share';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

import {page} from '../../styles/main';

import About from './About';
import Listing from './Listing';
import Preview from './Preview';
import Share from './Share';

const form = {
    realtor:{},
    listing: {},
}

export default function Main(){
    const history = useHistory()
    const [selectedIndex, setSelectedIndex] = React.useState(0);


    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };


    function moveNext(){
        setSelectedIndex(selectedIndex + 1)   
    }

    function handleNavigate(index){
        setSelectedIndex(index);
    }

    

    return(
        <div style={page.root}>
             <Grid direction="row" style={{height:"100vh"}} justifyContent="center" alignItems="center" container >
             <Grid item md={8} style={{height:"80%"}}>
             <Grid container direction="row" style={{height:"100%"}}>
                <Grid item md={4}>
                <Box sx={{padding:2, borderRadius:5, height:"100%", width: '90%', backgroundColor:"#E5E4E2" }}>

                    <Grid container style={{height:"100%"}} direction="column" justifyContent="space-between">
                        <Grid item md={1}>
                        <div style={{display:"flex", justifyContent:"flex-start"}}>
                        <img style={{width:150}}  src={require("../../images/logo/1x/Asset 1.png")}/>
                        </div>
                        
                        </Grid>
                        <Grid item md={8}>
                            <ListItem onClick={() => handleNavigate(0)} button disabled={selectedIndex == 0 || selectedIndex == 3}>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item md={1}>
                                    <PersonIcon fontSize="large" />
                                    </Grid>
                                    <Grid item md={8}>
                                    <Typography variant="body1"><b>Your details</b></Typography>
                                    <Typography variant="body1" color="textSecondary">Please tell us about yourself</Typography>
                                    </Grid>
                                    <Grid item md={1}>
                                    {selectedIndex > 0 ?  <CheckCircleOutlinedIcon fontSize="large"/> : <PanoramaFishEyeIcon fontSize="large" />}
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem onClick={() => handleNavigate(1)} button disabled={selectedIndex <= 0 || selectedIndex == 3}>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item md={1}>
                                    <ApartmentIcon fontSize="large" />
                                    </Grid>
                                    <Grid item md={8}>
                                    <Typography variant="body1"><b>Add Listing</b></Typography>
                                    <Typography variant="body1" color="textSecondary">Please tell us about the listing</Typography>
                                    </Grid>
                                    <Grid item md={1}>
                                    {selectedIndex > 1 ?  <CheckCircleOutlinedIcon fontSize="large"/> : <PanoramaFishEyeIcon fontSize="large" />  }
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem onClick={() => handleNavigate(2)} button disabled={selectedIndex <= 1 || selectedIndex == 3}>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item md={1}>
                                    <GridViewIcon fontSize="large" />
                                    </Grid>
                                    <Grid item md={8}>
                                    <Typography variant="body1"><b>Preview</b></Typography>
                                    <Typography variant="body1" color="textSecondary">See how your listing is displayed</Typography>
                                    </Grid>
                                    <Grid item md={1}>
                                    {selectedIndex > 2 ?  <CheckCircleOutlinedIcon fontSize="large"/> : <PanoramaFishEyeIcon fontSize="large" />  }
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem onClick={() => handleNavigate(3)} button disabled={selectedIndex <= 2}>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item md={1}>
                                    <ShareIcon fontSize="large" />
                                    </Grid>
                                    <Grid item md={8}>
                                    <Typography variant="body1"><b>Finish and Share</b></Typography>
                                    <Typography variant="body1" color="textSecondary">Share the link to your property listing</Typography>
                                    </Grid>
                                    <Grid item md={1}>
                                    {selectedIndex > 3 ?  <CheckCircleOutlinedIcon fontSize="large"/> : <PanoramaFishEyeIcon fontSize="large" />  }
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </Grid>
                        <Grid item>
                        <Button onClick={() => history.push("/")} 
                        fullWidth size="large" variant="outlined"><Typography>Already have an account</Typography></Button>
                        </Grid>
                    </Grid>
                   
                  
                    
                    </Box>
                </Grid>
                <Grid item md={7}>
                    {screens(selectedIndex, moveNext)}
                </Grid>

             </Grid>
            
                </Grid>
                
             </Grid>
        </div>
    )
}




function screens(index, move){
    switch(index){
        case 0:
            return(<About complete={() => move()} />)
        case 1:
            return(<Listing complete={() => move()} />)
        case 2:
            return(<Preview complete={() => move()} />)
        case 3: 
        return(<Share/>)
    }
}