import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Button from '@mui/material/Button';
import Typography  from '@mui/material/Typography';
import Grid  from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Chip  from '@mui/material/Chip';
import { requirePropFactory } from '@mui/material';

import KitchenIcon from '@mui/icons-material/Kitchen';
import BedIcon from '@mui/icons-material/Bed';
import ShowerIcon from '@mui/icons-material/Shower';
import LivingIcon from '@mui/icons-material/Living';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import GarageIcon from '@mui/icons-material/Garage';

import getSymbolFromCurrency from 'currency-symbol-map';

import AssetCardImage from '../../../components/image/onboarding/AssetCardMedia';


import {addSuggestion, removeSuggestion} from '../../../actions/suggestion';

export default function Main(props){
    const request = useSelector(state => state.request.request);
    const suggestions = useSelector(state => state.suggestion.suggestions);
    const realtor = useSelector(state => state.user.user);

    const [data, setData] = useState(suggestions ? suggestions.filter((item) => item.request == request.id) : []);


    const dispatch = useDispatch();
    const listing = props.data

    useEffect(() => {
        setData(suggestions ? suggestions.filter((item) => item.request == request.id) : [])
    }, [suggestions])


    function handleClick(){
        const body = {request: request.id, listing: props.data.id, realtor: realtor.id}
        dispatch(addSuggestion(body))
    }

    function handleDelete(){
        const suggest = data.filter((item) => item.listing == listing.id); 
        const body = {id: suggest[0].id, request: request.id}
        dispatch(removeSuggestion(body))
    }

    function getSuggestion(id){
        const suggest = data.filter((item) => item.listing == id);
        if(suggest.length > 0){
            return false
        }else{
            return true
        }
    }

    return(
                <Card elevation={0} sx={{ width:"100%", borderStyle:"solid", borderWidth:1, borderRadius:5, borderColor:"#E5E4E2" }}>
                    <AssetCardImage uri={listing.image1} />
                    <CardContent>
                        <Typography variant="h6" noWrap component="div">
                        <b>{listing.title}</b>
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                        {listing.address}
                        </Typography>
                        <Typography variant="body1"><b>{getSymbolFromCurrency("NGN")}{listing.price}</b></Typography>


                        <div style={{marginTop:10}} />
                        <Divider />


                        <div style={{marginTop:10}}>
                        
                        

                        <Grid container direction="row" style={{marginTop:10}}>
                            <Grid item xs={6}>
                                <Grid container direction="row">
                                    <Grid item>
                                    <BedIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography variant="body2" color="textSecondary">{listing.bedrooms} Bedrooms </Typography>
                                    </Grid>
                                </Grid>
                               
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction="row">
                                    <Grid item>
                                    <LivingIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography variant="body2"  color="textSecondary">{listing.livingrooms} Living Rooms</Typography>
                                    </Grid>
                                </Grid>
                               
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction="row">
                                    <Grid item>
                                        <ShowerIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography variant="body2"  color="textSecondary">{listing.bathrooms} Bathrooms</Typography>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction="row">
                                    <Grid item>
                                        <KitchenIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography variant="body2"  color="textSecondary">{listing.kitchens} Kitchens</Typography>
                                    </Grid>
                                </Grid>
                               
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction="row">
                                    <Grid item>
                                        <SquareFootIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography variant="body2"  color="textSecondary">{listing.total_area} sqft.</Typography>
                                    </Grid>
                                </Grid>

                                
                               
                            </Grid>

                            <Grid item xs={6}>
                            <Grid container direction="row">
                                    <Grid item>
                                        <GarageIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography variant="body2"  color="textSecondary">{listing.garages} Garages</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        </div>
                       
                    </CardContent>
                    
                    <CardActions>
                        {getSuggestion(listing.id) == true ? 
                            <Button onClick={handleClick} color="success" variant="contained" fullWidth>
                            <Typography variant="body2"><b>Suggest</b></Typography></Button>: 
                            <Button color="error" onClick={handleDelete} variant="contained" fullWidth>
                            <Typography variant="body2"><b>Withdraw</b></Typography></Button> }
                        
                    </CardActions>
                    
                    </Card>
                
    
    )
}