import { axiosHandler, defaultHandler } from "../requests/global.js";
import { unexpectedError, wait, notify } from "./index";
import { FETCH_LINK, FETCH_LINKS } from "../constants/artifacts.js";

import * as validator from "../validators/linkings.js";

export function getLinks(body) {
  const url = "/realtor/linking/all";
  return async (dispatch) => {
    dispatch(wait(true));
    try {
      const [res, status] = await defaultHandler(url, body);
      console.log(res);
      dispatch(fetchLinks(res ? res.response.linkings : []));
    } catch (ex) {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function addLink(body) {
  const url = "/realtor/linking/create";
  return async (dispatch) => {
    dispatch(wait(true));
    try {
      var [status, message] = validator.create(body);
      if (status == "success") {
        var [res, status] = await defaultHandler(url, body);
        console.log(res);
        dispatch(fetchLinks(res ? res.response.linkings : []));
      } else {
        dispatch(notify({ message: message, status: "error", show: true }));
      }
    } catch (ex) {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function fetchLink(payload) {
  return { type: FETCH_LINK, payload };
}

export function fetchLinks(payload) {
  return { type: FETCH_LINKS, payload };
}
