export function create(form){
    var status = "error"; var message = "";
    if(form.title === "" || form.title === null){
        message = "Please provide a title";
    }else{
        status = "success"
    }

    return [status, message]
}

