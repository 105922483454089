import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import ButtonBase from '@mui/material/ButtonBase';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';


import {wait, notify, unexpectedError} from '../../../actions/index';

import {uri} from '../../../constants/settings';

const base64 = require('base-64');

const styles = {
    input:{
        display: 'none',
    }
}

export default function Main(props){
    const dispatch = useDispatch();
    const [form, setForm] = useState({preview:""});
    const user = "";
    const pass = "";
    const credentials = user + ":" + pass;


    useEffect(() =>  {
      fetchPhoto();
    }, []);

    async function fetchPhoto(){
      const user = "mojeed.oyedeji@gmail.com";
      const pass = "omopeyemi";
      const credentials = user + ":" + pass;
      var url = "";
        if(uri.mode == "test"){
          url = 'http://127.0.0.50/realtor/images/fetchAvatar/';
        }else{
          url = 'https://api.arkquire.com/realtor/images/fetchAvatar/';
        }

      await fetch(url, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': "Basic " + base64.encode(credentials)
        },
        body: JSON.stringify({
          uri: props.uri,
          type: "avatar"
        })
      })
      .then((response) => response.blob())
      .then((res) => {
        if (res.size > 100){
          setForm({
            photo: res,
            preview: URL.createObjectURL(res),
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
    }

    return(
        <div>
        <ButtonBase disabled={false} >
        <label htmlFor={props.name}>
        {form.preview == "" ? 
            <Avatar style={{width:50, height:50}}> 
            <PersonIcon /> </Avatar>:
            <Avatar style={{width:50, height:50}}  src={form.preview} />
        }
        </label>
        </ButtonBase>
        </div>
    )
}