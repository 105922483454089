import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';



import EditForm from '../../components/listing/EditForm';


import {updateListing} from '../../actions/listings';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  
export default function Main(){
  const dispatch = useDispatch();
  const listing = useSelector(state => state.listing.listing);
  const [form, setForm] = useState(listing);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  function handleSave(){
    dispatch(updateListing())
  }

  function handleSavePublish(){

  }

  function handleClick(){

  }
    
    return(
        <div>

        <div style={{marginBottom:0}}>
          <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4"><b>Listings</b></Typography>
                </Grid>
            </Grid>
          </div>

          <div style={{marginTop:10, marginBottom:10}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="/listings">
                    All Listings
                  </Link>
                  <Typography color="text.primary">Edit Listing</Typography>
                </Breadcrumbs>
              </div>
              </Grid>
            </Grid>  
            </div>
           

      <div style={{marginTop:20}}/>

      <Grid container direction="row">
        <Grid item md={8}>
        <EditForm  />
        </Grid>
      </Grid>
     

     
       
        </div>
    )
}

