import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function Main(props){
    const notify = useSelector(state => state.app.notify);
    return(
        <Stack>
            {notify.show == true && <Alert severity={notify.status}>{notify.message}</Alert>}
        </Stack>
    )
}

