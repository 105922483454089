import {FETCH_SUGGESTION, FETCH_SUGGESTIONS} from  "../constants/suggestions";


const initialState = {
  suggestion:{
  },
  suggestions:[]
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_SUGGESTION:
      return Object.assign({}, state, {
         suggestion: action.payload
       });
    case FETCH_SUGGESTIONS:
      return Object.assign({}, state, {
         suggestions: action.payload
      });
  }
  return state;
}

export default reducer;
